import React, { ReactNode } from "react";
import classnames from "classnames";

//Style
import Styles from "./AnimateContainer.module.scss";

export interface AnimateContainerProps {
    children: ReactNode;
    animation: "fadeIn" | "fadeOut";
}

const AnimateContainer = ({ animation, children }: AnimateContainerProps) => {
    return <div className={classnames(Styles.animatedContainer, Styles[animation])}>{children}</div>;
};

export default AnimateContainer;
