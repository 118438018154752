import React from "react";
import Chip, { ChipProps } from "@vahak/core-ui/dist/components/Chip";
import COLORS from "@vahak/core-ui/dist/constants/colors";

interface PostingChipProps extends ChipProps {
    active?: boolean;
}

const PostingChip = ({ active, ...props }: PostingChipProps) => {
    return (
        <Chip
            radius={44}
            outlined
            {...(active
                ? {
                      filled: true,
                      fillColor: COLORS.BLUE_100,
                      outlineColor: COLORS.BLUE,
                      fontColor: COLORS.BLUE
                  }
                : {
                      filled: false,
                      fillColor: COLORS.GREY_200,
                      outlineColor: COLORS.GREY_200,
                      fontColor: COLORS.GREY_700
                  })}
            {...props}
        />
    );
};

export default PostingChip;
