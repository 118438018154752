import React, { useContext } from "react";

//Style
import Styles from "./ClearDueModal.module.scss";

//Context
import { AppContext } from "@vahak/core/dist/app-context";

//Hooks & services
import { BookingForMap, useGeneratePayment } from "../../services/vas/useGeneratePayment";
import useRazorpay from "../../services/useRazorpay";
import useMediaQuery from "@vahak/core-ui/dist/hooks/useMediaQuery";

//Components
import ModalOrBottomSheetWrapper from "@vahak/core-ui/dist/components/ModalOrBottomSheetWrapper";
import Flex from "@vahak/core-ui/dist/layout/Flex";
import { ModalCrossIcon } from "@vahak/core-ui/dist/components/Modal/Modal";
import Typography from "@vahak/core-ui/dist/components/Typography";
import Button from "@vahak/core-ui/dist/components/Button";
import Chip from "@vahak/core-ui/dist/components/Chip";

//Constants
import COLORS from "@vahak/core-ui/dist/constants/colors";

//Icons
import WarningRed from "@vahak/core/dist/standard-icons/warning-red.svg";
import { useCsDashboard } from "@vahak/core/dist/hooks/useCsDashboard";

interface ClearDueModalProps {}

const ClearDueModal = ({}: ClearDueModalProps) => {
    const { showClearDuePopup, setShowClearDuePopup, companyId, dueAmount } = useContext(AppContext);

    const isMobileScreen = useMediaQuery({ queryType: "mobile" });

    const { isCsDashboardUser } = useCsDashboard();

    const { mutateAsync: generatePayment } = useGeneratePayment();

    const { initializePayment } = useRazorpay();

    const handleClickClearDue = () => {
        if (isCsDashboardUser) return;
        generatePayment(
            {
                companyId: Number(companyId),
                redeemCashback: false,
                bookingAmountOrderRequest: [
                    {
                        amount: dueAmount,
                        booking_for: BookingForMap.CASHBACK_DUE
                    }
                ]
            },
            {
                onSuccess: (vasResponse) => {
                    const { data: { razorpay_order_id, order_amount } = {} } = vasResponse;
                    if (razorpay_order_id && order_amount && order_amount > 0) {
                        initializePayment({
                            order_id: razorpay_order_id,
                            amount: order_amount,
                            onSuccess: (response) => {
                                setShowClearDuePopup?.({ isOpen: false, dueAmount: 0 });
                            },
                            onError: (err) => {
                                setShowClearDuePopup?.({ isOpen: false, dueAmount: 0 });
                            },
                            onDismiss: () => {
                                setShowClearDuePopup?.({ isOpen: false, dueAmount: 0 });
                            }
                        });
                    } else if (order_amount === 0) {
                        setShowClearDuePopup?.({ isOpen: false, dueAmount: 0 });
                    }
                }
            }
        );
    };

    return (
        <ModalOrBottomSheetWrapper
            isOpen={showClearDuePopup}
            onToggle={() => setShowClearDuePopup?.({ isOpen: false, dueAmount: 0 })}
            modalSize="fit"
            paddedModal={false}
            paddedSheet={false}
            disableSheetSwipe
            renderTitle={
                <Flex justifyContent="space-between" alignItems="center" padding={"20px 20px 0 20px"}>
                    <Typography weight="semibold">Pending dues</Typography>
                    <ModalCrossIcon onClick={() => setShowClearDuePopup?.({ isOpen: false, dueAmount: 0 })} />
                </Flex>
            }
        >
            <Flex padding={"20px"} style={{ maxWidth: isMobileScreen ? "" : "400px" }} flexDirection="column" gap={20}>
                <Typography color={COLORS.GREY_700} weight="medium" size="sm">
                    Hi, you have pending dues because you did not confirm the booking after lorry confirmation.
                </Typography>

                <Chip radius={"8px"} size="auto" fillColor={"#FFE5E5"} filled>
                    <Flex alignItems="center" gap={10} padding={"13px"}>
                        <WarningRed />
                        <Typography weight="semibold" size="sm">
                            Total dues - ₹{Math.abs(Number(dueAmount))}
                        </Typography>
                    </Flex>
                </Chip>

                <div className={Styles.BtnWrapper}>
                    <Button isRounded blockBtn onClick={handleClickClearDue} disabled={isCsDashboardUser}>
                        {isCsDashboardUser ? "Ask user to clear dues" : "Clear Dues"}
                    </Button>
                </div>
            </Flex>
        </ModalOrBottomSheetWrapper>
    );
};

export default ClearDueModal;
