import React, { useContext, useState } from "react";

//Style
import Styles from "./RightSideSection.module.scss";

//Assets
import CrossIcon from "@vahak/core/dist/standard-icons/cross-icon.svg";

//Components
import LoginFormFieldWrapper from "../login-form-field-wrapper/LoginFormFieldWrapper";
import { useRouter } from "next/router";
import { GA4EventNames, GA4LoginOrRegistrationModalEventAttributes, IDs, ROUTES } from "@vahak/core/dist/constants";
import { CompanyServiceType, useEventTrackerService } from "@vahak/core/dist/_services";
import { AppContext } from "@vahak/core/dist/app-context";
import { GenericObject } from "@vahak/core-ui/dist/types";
import { LOGIN_FORM_STEPS } from "../common/contant";
import useMediaQuery from "@vahak/core-ui/dist/hooks/useMediaQuery";
import CrossIconBold from "@vahak/core/dist/icons/crossIconBold.svg";
import { getAllVwoABTestVariations } from "../../../methods/getABTestVariation";
import { AB_TEST_CAMPAIGN_IDS } from "../../../constants/abTestVariations";

interface RightSideSectionProps {}

const RightSideSection = ({}: RightSideSectionProps) => {
    const router = useRouter();

    const { setShouldPreventLayoutSwitch, serviceType } = useContext(AppContext);
    const [currentStep, setCurrentStep] = useState<LOGIN_FORM_STEPS>(LOGIN_FORM_STEPS.PHONE_NUMBER);

    const { sendGAandMoEngageEvent } = useEventTrackerService();

    const { abTestVariations }: any = getAllVwoABTestVariations();
    const ENV = process.env.REACT_APP_ENV || process.env.NEXT_PUBLIC_REACT_APP_ENV;
    const downloadAppTestCampaign =
        ENV === "production" ? AB_TEST_CAMPAIGN_IDS["downloadAppProd"] : AB_TEST_CAMPAIGN_IDS["downloadApp"];
    let mandatePostingProd = null;
    if (serviceType === CompanyServiceType.SHIPPER) {
        mandatePostingProd = AB_TEST_CAMPAIGN_IDS["mandatePostingShipperProd"];
    } else if (serviceType === CompanyServiceType.FLEET_OWNER) {
        mandatePostingProd = AB_TEST_CAMPAIGN_IDS["mandatePostingTruckerProd"];
    } else {
        mandatePostingProd = AB_TEST_CAMPAIGN_IDS["mandatePostingTransporterProd"];
    }
    const mandateLoadLorryPostTestCampaign =
        ENV === "production" ? mandatePostingProd : AB_TEST_CAMPAIGN_IDS["mandateLoadLorryPostingDev"];

    const handleEventTracking = (name: string, otherData?: GenericObject) => {
        const data = {
            ...([LOGIN_FORM_STEPS.PHONE_NUMBER, LOGIN_FORM_STEPS.OTP].includes(currentStep) && {
                source_screen: GA4LoginOrRegistrationModalEventAttributes.phone_number_registration
            }),
            ...([LOGIN_FORM_STEPS.COMPANY_NAME].includes(currentStep) && {
                source_screen: "company_registration"
            }),
            ...([LOGIN_FORM_STEPS.SUCCESS_SCREEN].includes(currentStep) && {
                source_screen: "onboarding_splash"
            }),
            ...otherData
        };

        sendGAandMoEngageEvent({
            name,
            data
        });
    };

    const toggleModal = () => {
        setShouldPreventLayoutSwitch?.(false);

        delete router.query["referral_code"];
        handleEventTracking(GA4EventNames.LOGIN_OR_SIGNUP_MODAL.close_clicked);
        router.replace({ ...router, hash: undefined });
    };

    const isMobileScreen = useMediaQuery({ queryType: "mobile" });
    const showCrossIcon =
        (abTestVariations[downloadAppTestCampaign] !== 3 || currentStep === LOGIN_FORM_STEPS.SUCCESS_SCREEN) &&
        (abTestVariations[mandateLoadLorryPostTestCampaign] !== 2 || currentStep !== LOGIN_FORM_STEPS.SUCCESS_SCREEN) &&
        currentStep !== LOGIN_FORM_STEPS.COMPANY_NAME;

    return (
        <div className={Styles.main}>
            {showCrossIcon ? (
                <div
                    className={Styles.crossIcon}
                    onClick={() => {
                        /**
                         * Jugaad for share modal
                         */
                        delete router.query["loadbid"];
                        delete router.query["lorrybid"];

                        toggleModal();
                    }}
                    id={IDs.auth.crossBtn}
                >
                    {isMobileScreen ? <CrossIconBold /> : <CrossIcon />}
                </div>
            ) : (
                <></>
            )}

            <LoginFormFieldWrapper setCurrentStep={setCurrentStep} />
        </div>
    );
};

export default RightSideSection;
