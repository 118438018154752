import { ScreenId } from "@vahak/core/dist/constants";
import { GA4EventNamesAdminConsole } from "@vahak/core/dist/constants/event-names";

export const getEventSourceScreenAdminConsole = (id: string) => {
    switch (id) {
        case ScreenId.admin:
            return {
                SOURCE_SCREEN: GA4EventNamesAdminConsole.TSO_SOURCE_SCREEN.tso_home,
                EVENT_NAME: GA4EventNamesAdminConsole.SIDE_NAV.home_clicked
            };

        case ScreenId.tsoLoadMarketPlace:
            return {
                SOURCE_SCREEN: GA4EventNamesAdminConsole.TSO_SOURCE_SCREEN.tso_load_market,
                EVENT_NAME: GA4EventNamesAdminConsole.SIDE_NAV.loadmarket_clicked
            };

        case ScreenId.tsoLorryMarketPlace:
            return {
                SOURCE_SCREEN: GA4EventNamesAdminConsole.TSO_SOURCE_SCREEN.tso_lorry_market,
                EVENT_NAME: GA4EventNamesAdminConsole.SIDE_NAV.lorrymarket_clicked
            };

        case ScreenId.myReferrals:
            return {
                SOURCE_SCREEN: GA4EventNamesAdminConsole.TSO_SOURCE_SCREEN.tso_my_users,
                EVENT_NAME: GA4EventNamesAdminConsole.SIDE_NAV.myusers_clicked
            };

        case ScreenId.newReferrals:
            return {
                SOURCE_SCREEN: GA4EventNamesAdminConsole.TSO_SOURCE_SCREEN.tso_claim_users,
                EVENT_NAME: GA4EventNamesAdminConsole.SIDE_NAV.claimuser_clicked
            };
        case ScreenId.loadEnquiryCards:
        case ScreenId.tsoLoadEnquiryCard:
            return {
                SOURCE_SCREEN: GA4EventNamesAdminConsole.TSO_SOURCE_SCREEN.tso_load_enquirycards,
                EVENT_NAME: GA4EventNamesAdminConsole.SIDE_NAV.load_enquirycards_clicked
            };

        case ScreenId.lorryEnquiryCards:
        case ScreenId.tsoLorryEnquiryCard:
            return {
                SOURCE_SCREEN: GA4EventNamesAdminConsole.TSO_SOURCE_SCREEN.tso_lorry_enquirycards,
                EVENT_NAME: GA4EventNamesAdminConsole.SIDE_NAV.lorry_enquirycards_clicked
            };

        case ScreenId.tsoActiveLorryPage:
            return {
                SOURCE_SCREEN: GA4EventNamesAdminConsole.TSO_SOURCE_SCREEN.tso_lorry_posted,
                EVENT_NAME: GA4EventNamesAdminConsole.SIDE_NAV.lorry_posted_clicked
            };

        case ScreenId.tsoInTransitLorryPage:
            return {
                SOURCE_SCREEN: GA4EventNamesAdminConsole.TSO_SOURCE_SCREEN.tso_intransit_lorries,
                EVENT_NAME: GA4EventNamesAdminConsole.SIDE_NAV.intransit_lorries_clicked
            };

        case ScreenId.tsoActiveLoadsPage:
            return {
                SOURCE_SCREEN: GA4EventNamesAdminConsole.TSO_SOURCE_SCREEN.tso_load_posted,
                EVENT_NAME: GA4EventNamesAdminConsole.SIDE_NAV.load_posted_clicked
            };

        case ScreenId.tsoInTransitLoadPage:
            return {
                SOURCE_SCREEN: GA4EventNamesAdminConsole.TSO_SOURCE_SCREEN.tso_intransit_loads,
                EVENT_NAME: GA4EventNamesAdminConsole.SIDE_NAV.intransit_loads_clicked
            };

        default:
            break;
    }
};
