import React, { MouseEventHandler } from "react";
import LocationIcon from "@vahak/core/dist/icons/location_blue_circle.svg";
import Flex from "@vahak/core-ui/dist/layout/Flex";
import Styles from "./CurrentLocation.module.scss";
import useMediaQuery from "@vahak/core-ui/dist/hooks/useMediaQuery";

interface CurrentLocationPropsType {
    onClick: MouseEventHandler<HTMLDivElement>;
    id?: string;
    showLabel?: boolean;
}
const CurrentLocation = ({ onClick, id, showLabel = true }: CurrentLocationPropsType) => {
    const isMobileScreen = useMediaQuery({ queryType: "mobile" });
    const handleEvents = (e: any) => {
        e?.preventDefault();
        e?.stopPropagation();
        onClick?.(e);
    };

    return (
        <div className={Styles.main} onTouchEnd={handleEvents} onMouseDown={handleEvents} id={id}>
            <LocationIcon />
            {!isMobileScreen && showLabel && (
                <div className={Styles.text} id={`${id}__text`}>
                    Get Current Location
                </div>
            )}
        </div>
    );
};

export default CurrentLocation;
