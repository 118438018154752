import React, { useContext } from "react";
import { AppContext } from "@vahak/core/dist/app-context";
import BottomNavLayout, { BottomNavLayoutProps } from "../bottom-nav-layout/BottomNavLayout";
import SideNavLayout, { SideNavLayoutProps } from "../side-nav-layout/SideNavLayout";

import Styles from "./MainPrivateRouteLayout.module.scss";

export interface PrivateRouteLayoutProps extends BottomNavLayoutProps, SideNavLayoutProps {}

const PrivateRouteLayout = ({ ...props }: Omit<PrivateRouteLayoutProps, "navContent">) => {
    const { isMobile } = useContext(AppContext);
    return !isMobile ? (
        <SideNavLayout className={Styles.sideNavLayout} navContent={<></>} {...props} />
    ) : (
        <BottomNavLayout className={Styles.bottomNavLayout} topNavContent={<></>} navContent={<></>} {...props} />
    );
};

export default PrivateRouteLayout;
