import React from "react";
import dynamic from "next/dynamic";

//Context
import { useRouter } from "next/router";
import { useLoadLorryPosting } from "../context/load-lorry-posting";
import StandaloneLorryPostingFormModal from "./posting/lorry-posting/standalone-lorry-posting-form-modal/StandaloneLorryPostingFormModal";

//Components
const StandaloneLoadPostingFormModal = dynamic(
    () => import("./posting/load-posting/standalone-load-posting-form-modal/StandaloneLoadPostingFormModal"),
    { ssr: false }
);
export interface CommonGlobalComponentsWrapperProps {}

const CommonGlobalComponentsWrapper = ({}: CommonGlobalComponentsWrapperProps) => {
    const router = useRouter();
    const { loadLorryData } = useLoadLorryPosting();
    const { type, data, isModalOpen, originationPage } = loadLorryData ?? {};
    let loadData = type === "load" ? data : undefined;
    let lorryData = type === "lorry" ? data : undefined;

    return (
        <>
            {isModalOpen && type === "load" && (
                <StandaloneLoadPostingFormModal editLoadData={loadData} originationPage={originationPage} />
            )}
            {isModalOpen && type === "lorry" && (
                <StandaloneLorryPostingFormModal editLorryData={lorryData} originationPage={originationPage} />
            )}
        </>
    );
};

export default CommonGlobalComponentsWrapper;
