export const mostSearchedCategoryList: any = {
    "online-truck-booking": [
        "Bangalore",
        "Hyderabad",
        "Mumbai",
        "Chennai",
        "Delhi",
        "Gurgaon",
        "Noida",
        "Coimbatore",
        "Ghaziabad",
        "Chandigarh",
        "udaipur",
        "kolkata",
        "navi mumbai",
        "thane",
        "bhiwandi",
        "pune",
        "nashik",
        "nagpur",
        "rajkot",
        "surat",
        "ahmedabad",
        "vadodara",
        "gandhinagar",
        "jaipur",
        "indore",
        "bhopal",
        "agra",
        "lucknow",
        "faridabad",
        "visakhapatnam",
        "vizag",
        "mysuru",
        "kanpur",
        "varanasi",
        "howrah",
        "gandhidham",
        "patna"
    ],
    "online-load-booking": [
        "Bangalore",
        "Hyderabad",
        "Mumbai",
        "Chennai",
        "Delhi",
        "Gurgaon",
        "Noida",
        "Coimbatore",
        "Ghaziabad",
        "Chandigarh",
        "udaipur",
        "kolkata",
        "navi mumbai",
        "thane",
        "bhiwandi",
        "pune",
        "nashik",
        "nagpur",
        "rajkot",
        "surat",
        "ahmedabad",
        "vadodara",
        "gandhinagar",
        "jaipur",
        "indore",
        "bhopal",
        "agra",
        "lucknow",
        "faridabad",
        "visakhapatnam",
        "vizag",
        "mysuru",
        "kanpur",
        "varanasi",
        "howrah",
        "gandhidham",
        "patna"
    ],
    transporter: [
        "bangalore",
        "hyderabad",
        "mumbai",
        "chennai",
        "delhi",
        "gurgaon",
        "noida",
        "coimbatore",
        "ghaziabad",
        "chandigarh",
        "udaipur",
        "kolkata",
        "navi mumbai",
        "thane",
        "bhiwandi",
        "pune",
        "nashik",
        "nagpur",
        "rajkot",
        "surat",
        "ahmedabad",
        "vadodara",
        "gandhinagar",
        "jaipur",
        "indore",
        "bhopal",
        "agra",
        "lucknow",
        "faridabad",
        "visakhapatnam",
        "vizag",
        "mysuru",
        "kanpur",
        "varanasi",
        "howrah",
        "gandhidham",
        "patna"
    ],
    "transport-service": [
        "bangalore",
        "hyderabad",
        "mumbai",
        "chennai",
        "delhi",
        "gurgaon",
        "noida",
        "coimbatore",
        "ghaziabad",
        "chandigarh",
        "udaipur",
        "kolkata",
        "navi mumbai",
        "thane",
        "bhiwandi",
        "pune",
        "nashik",
        "nagpur",
        "rajkot",
        "surat",
        "ahmedabad",
        "vadodara",
        "gandhinagar",
        "jaipur",
        "indore",
        "bhopal",
        "agra",
        "lucknow",
        "faridabad",
        "visakhapatnam",
        "vizag",
        "mysuru",
        "kanpur",
        "varanasi",
        "howrah",
        "gandhidham",
        "patna"
    ],
    // "truckBooking": [
    //
    //     "bangalore",
    //     "hyderabad",
    //     "mumbai",
    //     "chennai",
    //     "delhi",
    //     "gurgaon",
    //     "noida",
    //     "coimbatore",
    //     "ghaziabad",
    //     "chandigarh",
    //     "udaipur",
    //     "kolkata",
    //     "navi mumbai",
    //     "thane",
    //     "bhiwandi",
    //     "pune",
    //     "nashik",
    //     "nagpur",
    //     "rajkot",
    //     "surat",
    //     "ahmedabad",
    //     "vadodara",
    //     "gandhinagar",
    //     "jaipur",
    //     "indore",
    //     "bhopal",
    //     "agra",
    //     "lucknow",
    //     "faridabad",
    //     "visakhapatnam",
    //     "vizag",
    //     "mysuru",
    //     "kanpur",
    //     "varanasi",
    //     "howrah",
    //     "gandhidham",
    //     "patna"
    // ],
    "transport-directory": [
        "bangalore",
        "hyderabad",
        "mumbai",
        "chennai",
        "delhi",
        "gurgaon",
        "noida",
        "coimbatore",
        "ghaziabad",
        "chandigarh",
        "udaipur",
        "kolkata",
        "navi mumbai",
        "thane",
        "bhiwandi",
        "pune",
        "nashik",
        "nagpur",
        "rajkot",
        "surat",
        "ahmedabad",
        "vadodara",
        "gandhinagar",
        "jaipur",
        "indore",
        "bhopal",
        "agra",
        "lucknow",
        "faridabad",
        "visakhapatnam",
        "vizag",
        "mysuru",
        "kanpur",
        "varanasi",
        "howrah",
        "gandhidham",
        "patna"
    ],
    lorry: [
        { src: "Bangalore", dest: "Pune" },
        { src: "Bangalore", dest: "Mumbai" },
        { src: "Bangalore", dest: "Delhi" },
        { src: "Bangalore", dest: "Hyderabad" },
        { src: "Bangalore", dest: "Chennai" },
        { src: "Bangalore", dest: "Kolkata" },
        { src: "Mumbai", dest: "Delhi" },
        { src: "Mumbai", dest: "Hyderabad" },
        { src: "Mumbai", dest: "Kolkata" },
        { src: "Mumbai", dest: "Chennai" },
        { src: "Mumbai", dest: "Jaipur" },
        { src: "Mumbai", dest: "Ahmedabad" },
        { src: "Mumbai", dest: "Nagpur" },
        { src: "Delhi", dest: "Nagpur" },
        { src: "Delhi", dest: "Ahmedabad" },
        { src: "Delhi", dest: "Kolkata" },
        { src: "Delhi", dest: "Chennai" },
        { src: "Delhi", dest: "Hyderabad" },
        { src: "Delhi", dest: "Bangalore" }
    ]
};
