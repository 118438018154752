import React from "react";

//Style
import Styles from "./LeftSideSection.module.scss";

//Components
import Flex from "@vahak/core-ui/dist/layout/Flex";
import Typography from "@vahak/core-ui/dist/components/Typography";

//Constants
import COLORS from "@vahak/core-ui/dist/constants/colors";

//Assets
import LoginModalGraphic from "@vahak/core/dist/graphic-images/login-modal-graphic.svg";
import LoadWhite from "@vahak/core/dist/standard-icons/load-white.svg";
import VerifiedWhite from "@vahak/core/dist/standard-icons/verified-white.svg";
import LocationWhite from "@vahak/core/dist/standard-icons/Location-white.svg";
import TimeWhite from "@vahak/core/dist/standard-icons/time-white.svg";

interface LeftSideSectionProps {}

const LeftSideSection = ({}: LeftSideSectionProps) => {
    return (
        <div className={Styles.main}>
            <Flex flexDirection="column">
                <Typography size="xxl" color={COLORS.GREY_200} weight="semibold">
                    India’s largest transport marketplace
                </Typography>
                <Typography color={COLORS.GREY_200}>Trusted by 28 Lakh+ Users</Typography>

                <Flex flexDirection="column" gap={20} justifyContent="center" style={{ marginTop: "36px" }}>
                    <Typography color={COLORS.GREY_200}>
                        <LoadWhite /> 1000+ Daily Loads
                    </Typography>
                    <Typography color={COLORS.GREY_200}>
                        <VerifiedWhite /> 8 Lakh+ Verified Trucks
                    </Typography>
                    <Typography color={COLORS.GREY_200}>
                        <LocationWhite /> Unlimited trip tracking
                    </Typography>
                    <Typography color={COLORS.GREY_200}>
                        <TimeWhite /> Instant booking in Minutes
                    </Typography>
                </Flex>
            </Flex>
            <div className={Styles.imgWrapper}>
                <LoginModalGraphic />
            </div>
        </div>
    );
};

export default LeftSideSection;
