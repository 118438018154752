// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

if (process.env.NEXT_PUBLIC_REACT_APP_ENV === "production") {
    Sentry.init({
        dsn: "https://882d7b37894e4498a99d7031ad79ce0a@o359779.ingest.us.sentry.io/5550185",
        tracesSampleRate: 1.0,
        beforeSend(event) {
            return event;
        }
    });
}
