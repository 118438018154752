import React, { useContext, useEffect, useState } from "react";
import { useRouter } from "next/router";
import Styles from "./ServiceRequestModal.module.scss";
import ModalOrBottomSheetWrapper from "@vahak/core-ui/dist/components/ModalOrBottomSheetWrapper";
import Flex, { VerticalFlex } from "@vahak/core-ui/dist/layout/Flex";
import { ModalCrossIcon } from "@vahak/core-ui/dist/components/Modal/Modal";
import Typography from "@vahak/core-ui/dist/components/Typography";
import Input from "@vahak/core-ui/dist/components/Input";
import COLORS from "@vahak/core-ui/dist/constants/colors";
import Button from "@vahak/core-ui/dist/components/Button";
import { AppContext } from "@vahak/core/dist/app-context";
import { TicketType, VasType } from "../admin/ticket-system-helper/constants";
import { useToggleModalWithLocalState } from "@vahak/core-ui/dist/hooks";
import useMediaQuery from "@vahak/core-ui/dist/hooks/useMediaQuery";
import useCreateVasTicket from "../../services/vas/useCreateVasTicket";
import { toast } from "@vahak/core/dist/components/toast/toast";
import { stringOperations } from "@vahak/core-ui/dist/methods";
import AppImg from "../utils/AppImg";

export enum ServiceRequestType {
    GPS = "gps",
    CARGO_INSURANCE = "cargoInsurance",
    VEHICLE_INSURANCE = "vehicleInsurance",
    BUSINESS_LOAN = "businessLoan"
}

interface ServiceRequestModalProps {
    onClose: () => void;
}

const ServiceRequestModal = ({ onClose }: ServiceRequestModalProps) => {
    const { name, phoneNum, companyId, serviceRequestType, setServiceRequestType } = useContext(AppContext);

    const router = useRouter();

    const [data, setData] = useState({ name: name ?? "", phone: String(phoneNum ?? "") });
    const [ticketRaisedSuccessfully, setTicketRaisedSuccessfully] = useState(false);

    const { isModalOpen, toggleModal, openModal } = useToggleModalWithLocalState(Boolean(serviceRequestType));

    const isMobileScreen = useMediaQuery({ queryType: "mobile" });

    const getServiceTypeData = () => {
        switch (serviceRequestType) {
            case "gps":
                return {
                    title: "GPS",
                    ticketType: TicketType.VAS_LEADS_GPS,
                    vasType: VasType.GPS,
                    description: "Gain real-time shipment visibility and improve delivery efficiency"
                };
            // case "businessLoan":
            //     return {
            //         title: "Business Loan",
            //         ticketType: TicketType.VAS_LEADS_BUSINESS_LOAN,
            //         vasType: VasType.BUSINESS_LOAN,
            //         description: "Secure financing to grow your logistics business"
            //     };
            case "cargoInsurance":
                return {
                    title: "Cargo Insurance",
                    ticketType: TicketType.VAS_LEADS_CARGO,
                    vasType: VasType.CARGO,
                    description: "Protect your valuable goods in transit with comprehensive coverage"
                };
            // case "vehicleInsurance":
            //     return {
            //         title: "Vehicle Insurance",
            //         ticketType: TicketType.VAS_LEADS_VEHICLE_INSURANCE,
            //         vasType: VasType.VEHICLE_INSURANCE,
            //         description: "Ensure your fleet is covered against unforeseen accidents"
            //     };

            default:
                break;
        }
    };

    const renderSuccess = () => {
        return (
            <div className={Styles.successScreen}>
                <Flex flexDirection="column" alignItems="center" gap={23} margin={"0 0 10px 0"}>
                    <AppImg
                        src={"/ticket-raised-success.svg"}
                        alt={""}
                        width={isMobileScreen ? 134 : 250}
                        height={isMobileScreen ? 81 : 152}
                        unoptimized
                    />

                    <Typography size="md" align="center" weight="semibold" color={COLORS.GREY_1000}>
                        Ticket submitted <br /> Successfully
                    </Typography>
                    <Typography align="center" weight="medium" color={COLORS.GREY_600}>
                        Our Agent will contact you shortly
                    </Typography>
                </Flex>
            </div>
        );
    };

    const getSourceScreen = () => {
        switch (router.pathname) {
            case "/vas/cargo-insurance":
                return "Cargo Insurance";
            case "/vas/commercial-vehicle-insurance":
                return "Commercial Vehicle Insurance";

            default:
                return "Requested from web - Top nav";
        }
    };

    const { mutateAsync: raiseVasTicket } = useCreateVasTicket();

    const handleClickRaiseTicket = () => {
        raiseVasTicket(
            {
                company_id: Number(companyId),
                vas_type: getServiceTypeData()?.vasType ?? 0,
                reference_type: 1,
                user_name: data.name,
                phone_number: data.phone,
                ...(serviceRequestType === ServiceRequestType.GPS && {
                    plan_id: 12,
                    no_of_devices: 0
                })
            },
            {
                onSuccess(data) {
                    setTicketRaisedSuccessfully(true);
                },
                onError(error) {
                    setServiceRequestType?.(undefined);
                    toggleModal();
                    toast.error(error.message);
                }
            }
        );
    };

    useEffect(() => {
        if (serviceRequestType) {
            openModal();
        }
    }, [serviceRequestType]);

    return (
        <ModalOrBottomSheetWrapper
            isOpen={isModalOpen || true}
            paddedModal={false}
            modalSize="fit"
            showHeader={false}
            borderRadius={16}
            sheetBorderRadius={16}
            disableSheetSwipe
        >
            <Flex className={Styles.main} flexDirection="column" gap={12}>
                <Flex justifyContent="space-between" alignItems="flex-start" style={{ marginBottom: 20 }}>
                    {!ticketRaisedSuccessfully ? (
                        <VerticalFlex gap={10}>
                            <Typography as="p" size="xl" weight="semibold">
                                {getServiceTypeData()?.title}
                            </Typography>
                            <Typography as="p" size="s" weight="medium">
                                {getServiceTypeData()?.description}
                            </Typography>
                        </VerticalFlex>
                    ) : (
                        <></>
                    )}
                    <ModalCrossIcon
                        onClick={() => {
                            setServiceRequestType?.(undefined);
                            toggleModal();
                            onClose();
                        }}
                        id={`serviceRequestPopup--closeBtn`}
                    />
                </Flex>
                {ticketRaisedSuccessfully ? (
                    renderSuccess()
                ) : (
                    <>
                        <Flex flexDirection="column" gap={13}>
                            <Typography size="m" weight="medium" color={COLORS.GREY_700}>
                                Enter your name
                            </Typography>
                            <Input
                                id={""}
                                name={"name"}
                                type="text"
                                value={data.name}
                                onChange={(e: any) => {
                                    setData({
                                        name: stringOperations(e.target.value, ["removeSpecialCharacters"]),
                                        phone: data.phone
                                    });
                                }}
                                placeholder=""
                                length={20}
                                autoFocus
                            />
                        </Flex>
                        <Flex flexDirection="column" gap={13}>
                            <Typography size="m" weight="medium" color={COLORS.GREY_700}>
                                Enter your phone number
                            </Typography>
                            <Input
                                id={""}
                                name={"phone"}
                                type="text"
                                value={data.phone}
                                onChange={(e: any) => {
                                    setData({ name: data.name, phone: e.target.value.replace(/\D/g, "") });
                                }}
                                length={10}
                                prefixNode={
                                    <Typography color={COLORS.BLACK} size="md" mSize="s" weight="semibold">
                                        +91 -{" "}
                                    </Typography>
                                }
                            />
                        </Flex>
                        <div className={Styles.submitBtn}>
                            <Button
                                blockBtn
                                onClick={handleClickRaiseTicket}
                                disabled={!(data.name && data.phone.length === 10)}
                            >
                                Submit
                            </Button>
                        </div>
                    </>
                )}
            </Flex>
        </ModalOrBottomSheetWrapper>
    );
};

export default ServiceRequestModal;
