import React from "react";
import classnames from "classnames";

import AppImg from "../AppImg";

// Style
import Styles from "./AvatarImage.module.scss";
import { getImageNameFromURL } from "../../../utils/getImageNameFromURL";

// Interface
interface BaseProps {
    imageUrl?: string;
    alt?: string;
    size?: "xs" | "s" | "sm" | "md" | "lg" | "custom";
    firstName?: string;
    lastName?: string;
    defaultUrl?: string;
    className?: string;
    onClick?: () => void;
}

interface CustomSizeProps extends BaseProps {
    size: "custom";
    imageSize: number;
}
interface PredifinedSizeProps extends BaseProps {
    size?: "xs" | "s" | "sm" | "md" | "lg";
    imageSize?: never;
}

type Prop = CustomSizeProps | PredifinedSizeProps;

const AvatarImage = ({ size = "xs", ...props }: Prop) => {
    const { imageSize } = props as CustomSizeProps;
    const { imageUrl, alt, firstName, lastName, defaultUrl, className, onClick } = props as PredifinedSizeProps;
    const sizes = {
        xs: 36,
        s: 40,
        sm: 45,
        md: 60,
        lg: 100,
        custom: imageSize
    };

    const initial = [];
    if (firstName || lastName) {
        if (firstName) {
            initial.push(firstName[0]);
        }

        if (lastName) {
            initial.push(lastName[0]);
        }
    }
    return (
        <div
            className={classnames(Styles.imageContainer, className)}
            style={{ width: sizes[size], height: sizes[size] }}
            onClick={onClick}
        >
            <AppImg
                src={getImageNameFromURL(imageUrl) || getImageNameFromURL(defaultUrl) || `default.png`}
                alt={alt}
                width={sizes[size]}
                height={sizes[size]}
                placeholder="empty"
            />
        </div>
    );
};

export default AvatarImage;
