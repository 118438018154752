import React, { forwardRef, ReactNode } from "react";

import SideNavLayout, { SidebarFCRef } from "../side-nav-layout-new/SideNavLayout";
import { useGetLayoutOptions } from "../methods/useGetLayoutOptions";

interface SideNavLayoutWrapperProps {
    children: ReactNode;
    isCollapsed?: boolean;
    toggleSideBar?: () => void;
    ref?: React.LegacyRef<SidebarFCRef>;
}

const SideNavLayoutWrapper = forwardRef<SidebarFCRef, SideNavLayoutWrapperProps>(
    ({ children, isCollapsed, toggleSideBar }, ref) => {
        const { navMenuOption, activeMenu } = useGetLayoutOptions();

        return (
            <SideNavLayout
                navMenuOption={navMenuOption}
                activeTab={activeMenu}
                isCollapsed={isCollapsed}
                toggleSideBar={toggleSideBar}
                ref={ref}
            >
                {children}
            </SideNavLayout>
        );
    }
);

export default SideNavLayoutWrapper;
