import React, { useEffect } from "react";
import Styles from "./AppMandatePopup.module.scss";
import Button from "@vahak/core-ui/dist/components/Button";
import Typography from "@vahak/core-ui/dist/components/Typography";
import Flex from "@vahak/core-ui/dist/layout/Flex";
import COLORS from "@vahak/core-ui/dist/constants/colors";
import AppImg from "../../../utils/AppImg";
import { useEventTrackerService } from "@vahak/core/dist/_services/hooks/useEventTrackerService";
import { useRouter } from "next/router";
import CloseIcon from "@vahak/core/dist/icons/CloseIconBlack.svg";
import IDs from "../../../../constants/automation";

interface AppMandatePopupProps {
    onCloseBtnClick: () => void;
}

const SignupMandatePopup = ({ onCloseBtnClick }: AppMandatePopupProps) => {
    const { sendGAandMoEngageEvent } = useEventTrackerService();
    const router = useRouter();

    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "unset";
        };
    }, []);
    return (
        <div className={Styles.main}>
            <div className={Styles.content}>
                <div className={Styles.banner}>
                    <AppImg src={"/app-download-baner.svg"} width={343} height={333} unoptimized />
                </div>
                {/* <div className={Styles.closeIcon} onClick={onCloseBtnClick}>
                    <CloseIcon />
                </div> */}
                <Flex flexDirection="column" gap={12} className={Styles.innerContent} alignItems="center">
                    <Typography weight="bold" mSize="md" align="center">
                        SIGN UP ON VAHAK NOW!
                    </Typography>
                    <Typography mSize="s" align="center" color={COLORS.GREY_700} weight="medium">
                        Book from 1000+ verified loads and lorries posted everyday in just a few clicks!
                    </Typography>

                    <Button
                        id={IDs.mandatePopup.mandateSignupBtn}
                        blockBtn
                        onClick={() => {
                            window.VWO?.event("mandate_signup");
                            onCloseBtnClick();
                            sendGAandMoEngageEvent({
                                name: "mandate_signup_clicked_web",
                                data: {
                                    source_section: "Web_App_Download_Mandate"
                                }
                            });
                            router.push({ hash: "#Login" });
                        }}
                    >
                        Login / Signup
                    </Button>
                </Flex>
            </div>
        </div>
    );
};

export default SignupMandatePopup;
