export { default as BottomNavLayout } from "./bottom-nav-layout/BottomNavLayout";
export * from "./bottom-nav-layout/BottomNavLayout";
export { default as TopNavLayout } from "./top-nav-layout/TopNavLayout";
export * from "./top-nav-layout/TopNavLayout";
export { default as DefaultTopNavLayout } from "./default-top-nav-layout/DefaultTopNavLayout";
export * from "./default-top-nav-layout/DefaultTopNavLayout";
export { default as MainPrivateRouteLayout } from "./main-private-route-layout/MainPrivateRouteLayout";
export * from "./main-private-route-layout/MainPrivateRouteLayout";
export { default as SideNavbar } from "./side-navbar/SideNavbar";
export * from "./side-navbar/SideNavbar";
export { default as SideNavLayout } from "./side-nav-layout/SideNavLayout";
export * from "./side-nav-layout/SideNavLayout";
// export { default as Footer } from "./footer/Footer";
// export * from "./footer/Footer";
