import { Cookies } from "react-cookie";
import { generateUrlParamsFromObject, sleep, timer } from "@vahak/core/dist/methods";
import { useRouter } from "next/router";
import React, { useContext, useEffect, useState } from "react";
import { useGetTrueCallerUserDetails } from "../services/truecaller-login/useTrueCallerLogin";
import { AppContext } from "@vahak/core/dist/app-context";
import LoginFormModalOld from "./auth/login-modal/LoginFormModalOld";
import {
    COOKIES,
    GA4EventNames,
    GA4LoginOrRegistrationModalEventAttributes,
    VWOEvents
} from "@vahak/core/dist/constants";
import {
    WebsiteUserType,
    moEngageUserAttributeTrack,
    moEngageUserLogIn,
    useEventTrackerService
} from "@vahak/core/dist/_services";
import LoginFormNew from "./login-form-new/LoginFormNew";
import { getAllVwoABTestVariations } from "../methods/getABTestVariation";
import { AB_TEST_CAMPAIGN_IDS } from "../constants/abTestVariations";

function uuidv4() {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) =>
        (+c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))).toString(16)
    );
}

const LoginModalGlobalWrapper = () => {
    const {
        setName,
        setCompanyId,
        setIsPhoneVerified,
        setCompanyLocation,
        setCompanyLogo,
        setUserId,
        setServiceType,
        setSessionId,
        isPhoneVerified,
        companyId,
        shouldPreventLayoutSwitch
    } = useContext(AppContext);

    const router = useRouter();

    if (companyId && isPhoneVerified && !shouldPreventLayoutSwitch) {
        return <></>;
    }

    const ENV = process.env.REACT_APP_ENV || process.env.NEXT_PUBLIC_REACT_APP_ENV;
    const cookies = new Cookies();

    /**
     * Temp condition check
     * This will be removed when login modal is refactored to use context or local state
     */
    const isLoginModalOpen = router.asPath.split("#")[1]?.includes("Login");

    function isMobile() {
        const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
        return regex.test(window?.navigator.userAgent);
    }

    const [trueCallerReqId, setTrueCallerReqId] = useState("");

    const [enableTrueCallerApi, setEnableTrueCallerApi] = useState({ enable: false, poolingCount: 0 });
    const [showLoginModal, setShowLoginModal] = useState(true);

    const [isFocused, setIsFocused] = useState(true);

    useEffect(() => {
        const checkFocus = () => {
            setIsFocused(document.hasFocus());
        };

        // Initial check
        checkFocus();

        // Add event listeners to detect focus changes
        window.addEventListener("focus", checkFocus);
        window.addEventListener("blur", checkFocus);

        // Cleanup event listeners on component unmount
        return () => {
            window.removeEventListener("focus", checkFocus);
            window.removeEventListener("blur", checkFocus);
        };
    }, []);

    const enum TRUE_CALLER_STATUS {
        FLOW_INVOKED = "flow_invoked",
        USE_ANOTHER_NUMBER = "use_another_number",
        USER_REJECTED = "user_rejected"
    }

    // useEffect(() => {
    //     if (typeof window != "undefined" && window && isLoginModalOpen && isMobile() && !isPhoneVerified) {
    //         const is_iOS = window?.navigator.platform.indexOf("Mac") === 0 || window?.navigator.platform === "iPhone";
    //         if (is_iOS) return;

    //         const reqId = uuidv4();
    //         const trueCallerConfig = {
    //             type: "btmsheet",
    //             requestNonce: reqId,
    //             partnerKey: process.env.NEXT_PUBLIC_TRUE_CALLER_APP_KEY,
    //             partnerName: "Vahak",
    //             lang: "en",
    //             loginPrefix: "continue",
    //             loginSuffix: "verifymobile",
    //             ctaPrefix: "use",
    //             ctaColor: "%2355FC",
    //             ctaTextColor: "%23ffffff",
    //             btnShape: "round",
    //             skipOption: "useanothermethod",
    //             ttl: 20000
    //         };
    //         const queryParams = generateUrlParamsFromObject(trueCallerConfig);

    //         const trueCallerWindow: any = `truecallersdk://truesdk/web_verify?${queryParams}`;
    //         window.location = trueCallerWindow;
    //         setTrueCallerReqId(reqId);
    //     }
    // }, [isLoginModalOpen]);

    // useEffect(() => {
    //     let timerId: any;
    //     if (isLoginModalOpen && isFocused && !isPhoneVerified && trueCallerReqId) {
    //         timerId = setTimeout(() => {
    //             setEnableTrueCallerApi({ enable: true, poolingCount: 0 });
    //         }, 1000);
    //     }
    //     return () => clearTimeout(timerId);
    // }, [isFocused]);

    const { sendGAandMoEngageEvent } = useEventTrackerService();

    // useGetTrueCallerUserDetails(
    //     {
    //         requestId: trueCallerReqId
    //     },
    //     {
    //         enabled: Boolean(trueCallerReqId !== "" && isLoginModalOpen && enableTrueCallerApi.enable),
    //         retry: 0,
    //         onSuccess: async (data: any) => {
    //             setTrueCallerReqId("");

    //             /**
    //              * This is base case to check if user did anything else other than continue
    //              * From api we'll get this message "use_another_number" & "user_rejected"
    //              */
    //             if (data?.message === "use_another_number" || data?.message === "user_rejected") {
    //                 return;
    //             }
    //             setShowLoginModal(false);

    //             delete router.query["referral_code"];
    //             router.replace({ ...router, hash: undefined });

    //             if (data) {
    //                 // login
    //                 cookies.set(COOKIES.token, data.access_token, {
    //                     secure: ENV === "production",
    //                     sameSite: "strict",
    //                     path: "/",
    //                     maxAge: 24 * 60 * 60 * 30
    //                 });
    //                 setSessionId?.(data?.session_id);
    //                 setIsPhoneVerified?.(true);
    //                 setName?.(data.c_name);
    //                 setCompanyId?.(data.c_id);
    //                 setCompanyLocation?.(data.c_loc);
    //                 setUserId?.(data.u_id);
    //                 setCompanyLogo?.(data?.profile_image);
    //                 if (data.c_serv) setServiceType?.(data.c_serv);
    //             }
    //             if (!Boolean(data.c_id)) {
    //                 sendGAandMoEngageEvent({
    //                     name: GA4EventNames.LOGIN_OR_SIGNUP_MODAL.user_signup,
    //                     data: {
    //                         source_screen: GA4LoginOrRegistrationModalEventAttributes.phone_number_registration,
    //                         phone_number: data.phone_number,
    //                         sign_up_via: "truecaller",
    //                         user_id: data.u_id
    //                     }
    //                 });
    //                 return;
    //             }
    //             sendGAandMoEngageEvent({
    //                 name: GA4EventNames.LOGIN_OR_SIGNUP_MODAL.user_login,
    //                 data: {
    //                     source_screen: GA4LoginOrRegistrationModalEventAttributes.phone_number_registration,
    //                     phone_number: data.phone_number,
    //                     login_via: "truecaller",
    //                     company_id: data.c_id,
    //                     user_id: data.u_id
    //                 }
    //             });
    //             moEngageUserLogIn(Number(data.u_id));
    //             moEngageUserAttributeTrack("Name", data.c_name);
    //             moEngageUserAttributeTrack("Phone", data.phone_number);
    //             moEngageUserAttributeTrack("company_id", data.c_id);
    //             moEngageUserAttributeTrack("user_id", data.u_id);
    //             moEngageUserAttributeTrack("user_type", WebsiteUserType.CLIENT);

    //             window?.VWO?.event(VWOEvents.login_success);
    //         },
    //         onError(err: any) {
    //             setEnableTrueCallerApi({ ...enableTrueCallerApi, enable: false });
    //             let timeOut;
    //             if (err.message.includes(TRUE_CALLER_STATUS.FLOW_INVOKED) && enableTrueCallerApi.poolingCount < 3) {
    //                 timeOut = setTimeout(() => {
    //                     setEnableTrueCallerApi({ enable: true, poolingCount: enableTrueCallerApi.poolingCount + 1 });
    //                 }, 3000);
    //                 return;
    //             } else {
    //                 timeOut && clearTimeout(timeOut);
    //                 setEnableTrueCallerApi({ ...enableTrueCallerApi, enable: false });
    //                 setShowLoginModal(true);
    //                 return;
    //             }
    //         }
    //     }
    // );

    return <>{isLoginModalOpen && showLoginModal ? <LoginFormNew /> : <></>}</>;
};

export default LoginModalGlobalWrapper;
