import React, { useState, useContext, Fragment } from "react";
import Link from "next/link";

// Utils & helper functions
import { AppContext } from "@vahak/core/dist/app-context";
import { ga4sendEvent } from "@vahak/core/dist/_services/user-analytics.service";
import { mostSearchedLinksMenus } from "@vahak/core/dist/constants/mostSearchedLinks";
import { MostSearchedLinksItemsGaEvents } from "@vahak/core/dist/constants/eventsMapObj";

// Icons
import Plus from "@vahak/core/dist/icons/Plus.svg";
import Minus from "@vahak/core/dist/icons/Minus.svg";
import CategoryList from "./CategoryList/CategoryList";
import MobileFilter from "./MobileFilter/MobileFilter";
import classNames from "classnames";

const MostSearchedLinks = () => {
    const { isMobile } = useContext(AppContext);
    const [isOpen, setIsOpen] = useState(true);
    const [activeSearch, setActiveSearch] = useState(mostSearchedLinksMenus[0].key);
    const [showLinks, setShowLinks] = useState(true);
    const [title, setTitle] = useState(mostSearchedLinksMenus[0].title);
    const [activeIndex, setActiveIndex] = useState(0);

    const handleClick = (val: any) => {
        const { currentSearchFilter, title, index } = val;

        let calVal = MostSearchedLinksItemsGaEvents[currentSearchFilter];
        if (calVal !== undefined) {
            ga4sendEvent("Websitemain_top_navigation_web", {
                value: calVal
            });
        }

        if (activeSearch === currentSearchFilter) {
            setShowLinks((state) => !state);
        } else {
            setActiveSearch(currentSearchFilter);
            setShowLinks(true);
            setActiveIndex(index);
            setTitle(title);
        }
    };

    return (
        <>
            <div className={`mostSearchedLinks ${isOpen ? "active" : ""}`}>
                <div
                    onClick={() => {
                        if (isOpen) {
                            setShowLinks(false);
                        } else {
                            setShowLinks(true);
                        }

                        setIsOpen((state) => !state);
                        // setActiveSearch("onlineTruckBooking");
                    }}
                >
                    <Link href="#" passHref prefetch={false}>
                        <>
                            {" "}
                            <span>Most Searched Links</span> {isOpen ? <Minus /> : <Plus />}
                        </>
                    </Link>
                </div>
            </div>
            {isMobile ? (
                <div className={classNames("Footer_mobile-link-container", isOpen && "active")}>
                    <MobileFilter data={mostSearchedLinksMenus} />
                </div>
            ) : (
                <>
                    <nav className={`nav ${isOpen ? "active" : ""}`}>
                        {mostSearchedLinksMenus.map((menu, index) => (
                            <Fragment key={menu.key}>
                                <div
                                    className={`nav-item ${menu.key === activeSearch && showLinks ? "is-active" : ""}`}
                                    onClick={() =>
                                        handleClick({
                                            currentSearchFilter: menu.key,
                                            isOpen: !isOpen,
                                            title: menu.title,
                                            index: index
                                        })
                                    }
                                >
                                    <Link href="#" active-color="orange" passHref prefetch={false}>
                                        <>
                                            <span>{menu.title}</span>{" "}
                                            {menu.key === activeSearch && showLinks ? <Minus /> : <Plus />}
                                        </>
                                    </Link>
                                </div>

                                <div
                                    className={`${
                                        menu.key === activeSearch || activeIndex - 1 === index
                                            ? "active"
                                            : "horizontalDivider"
                                    }`}
                                />
                            </Fragment>
                        ))}
                    </nav>

                    {<CategoryList selectedList={activeSearch || ""} title={title} isOpen={showLinks} />}
                </>
            )}
        </>
    );
};

export default MostSearchedLinks;
