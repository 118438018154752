import React from "react";
import { FormikProps } from "formik";

//Style
import Styles from "./PhoneNumberField.module.scss";

//Components
import Input from "@vahak/core-ui/dist/components/Input";
import { LoginFormFieldsTypes, loginFormFieldNames } from "../../common/contant";
import COLORS from "@vahak/core-ui/dist/constants/colors";
import Typography from "@vahak/core-ui/dist/components/Typography";
import Button from "@vahak/core-ui/dist/components/Button";

//Methods
import { removeNonNumeric } from "@vahak/core-ui/dist/methods";

//Assets
import GreenTick from "@vahak/core/dist/standard-icons/tick.svg";
import WhatsappIcon from "@vahak/core/dist/icons/WhatsappGreen.svg";

import { GA4EventNames, GA4LoginOrRegistrationModalEventAttributes, IDs } from "@vahak/core/dist/constants";
import Flex from "@vahak/core-ui/dist/layout/Flex";
import Checkbox, { CheckboxOnChangeHandler } from "@vahak/core-ui/dist/components/Checkbox";
import { useEventTrackerService } from "@vahak/core/dist/_services";
import TextCTA from "@vahak/core-ui/dist/components/TextCTA";
import useMediaQuery from "@vahak/core-ui/dist/hooks/useMediaQuery";

interface PhoneNumberFieldProps {
    formik: FormikProps<LoginFormFieldsTypes>;
}

const PhoneNumberField = ({ formik }: PhoneNumberFieldProps) => {
    const { sendGAandMoEngageEvent } = useEventTrackerService();
    const onChangePhoneNum: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        e.target.value = removeNonNumeric(e.target.value.slice(0, 10));
        formik.handleChange(loginFormFieldNames.phoneNumber)(e);

        if (e.target.value.length === 10) {
            sendGAandMoEngageEvent({
                name: GA4EventNames.LOGIN_OR_SIGNUP_MODAL.custom_number_entered,
                data: {
                    source_screen: GA4LoginOrRegistrationModalEventAttributes.phone_number_registration,
                    custom_number: e.target.value
                }
            });
        }
    };

    const onChangeWACheckbox: CheckboxOnChangeHandler = (e) => {
        formik.setFieldValue(loginFormFieldNames.whatsappUpdatesEnabled, e?.target?.checked);

        /**
         * Events
         */
        sendGAandMoEngageEvent(
            {
                name: GA4EventNames.LOGIN_OR_SIGNUP_MODAL.wa_optin_toggled,
                data: {
                    source_screen: GA4LoginOrRegistrationModalEventAttributes.phone_number_registration,
                    toggle_status: e?.target?.checked
                }
            },
            false
        );
    };

    const isMobileScreen = useMediaQuery({ queryType: "mobile" });
    return (
        <Flex flexDirection="column" gap={10} className={Styles.main}>
            {!isMobileScreen ? (
                <Typography color={COLORS.GREY_500} weight="medium">
                    Enter your mobile number to proceed
                </Typography>
            ) : (
                <></>
            )}
            <Input
                type="text"
                name={loginFormFieldNames.phoneNumber}
                inputMode="tel"
                value={String(formik.values.phoneNumber || "")}
                onChange={onChangePhoneNum}
                prefixNode={
                    <Typography color={COLORS.GREY_1000} weight="medium" lineHeight={1}>
                        +91 -
                    </Typography>
                }
                placeholder={isMobileScreen ? "Enter your mobile number here" : "Enter 10 digit mobile number"}
                state={String(formik.values.phoneNumber).length === 10 ? "success" : undefined}
                suffixNode={
                    <div style={{ marginRight: "-25px" }}>
                        {String(formik.values.phoneNumber).length === 10 ? <GreenTick /> : null}
                    </div>
                }
                id={IDs.auth.phoneNumber}
                autoFocus
            />

            <div className={Styles.btnWrapper}>
                <Button isRounded blockBtn disabled={!formik.isValid} id={IDs.auth.submitBtn}>
                    Get OTP
                </Button>
            </div>

            <Flex flexDirection="column" style={{ marginTop: "14px" }} gap={10}>
                <Typography align="center" size={isMobileScreen ? "xs" : "sm"}>
                    <TextCTA size={isMobileScreen ? "xs" : "sm"} onClick={() => window.open("/terms-and-conditions")}>
                        Terms of use
                    </TextCTA>{" "}
                    {isMobileScreen ? "and" : "&"}{" "}
                    <TextCTA size={isMobileScreen ? "xs" : "sm"} onClick={() => window.open("/privacy-policy")}>
                        Privacy Policy
                    </TextCTA>
                </Typography>
                <div className={Styles.termsAndCondition}>
                    <Checkbox
                        id={IDs.auth.optWa}
                        alignItems="center"
                        label={
                            <Typography color={COLORS.GREY_700} size={isMobileScreen ? "xs" : "sm"} weight="medium">
                                Get notifications through WhatsApp, SMS and RCS
                            </Typography>
                        }
                        name="opt-wa"
                        onChange={onChangeWACheckbox}
                        value={!!formik.values.whatsappUpdatesEnabled}
                    />
                </div>
            </Flex>
        </Flex>
    );
};

export default PhoneNumberField;
