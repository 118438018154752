import DefaultLayout from "../components/layout/default-layout/DefaultLayout";
import DashboardNav from "../components/layout/dashboard-navbar/DashboardNavbar";
import { DefaultTopNavLayout } from "../components/layout";
import {
    MARKETPLACE_ROUTE,
    LISTING_PAGE,
    PREMIUM_LOAD_TRIP,
    USER_PROFILE_PAGE,
    MOBILE_APP,
    PAYMENT_LIST,
    BULK_LOADS_LIST,
    TRACK_VEHICLE,
    HOME_ROUTE,
    DASHBOARD_ROUTE,
    TRANSPORT_DIRECTORY,
    MEMBERSHIP_PAYMENT,
    MEMBERSHIP_UPGRADE,
    MEMBERSHIP,
    MEMBERSHIP_HISTORY,
    BANK_DETAILS,
    MEMBERSHIP_FEATURE,
    TRANSPORT_NAGAR,
    MY_LORRIES_PAGE
} from "../constants/route-constants";
import PrivateRoute from "../components/layout/private-route/PrivateRoute";
import PrivatePremiumRoute from "../components/layout/private-premium-route/PrivatePremiumRoute";
import { NavBarName } from "../components/layout/navbar-menu-items";
import { MobileAppWebViewLayout } from "../components/layout/mobile-app-no-navbar/MobileAppWebViewLayout";
import SideNavLayoutWrapper from "../components/layout/SideNavLayoutWrapper/SideNavLayoutWrapper";
import DefaultLayoutWrapper from "../components/layout/DefaultLayoutWrapper/DefaultLayoutWrapper";
import { MembershipLayoutWrapper } from "../components/layout/membership-layout-wrapper/MembershipLayoutWrapper";
import { PlainLayout } from "../components/layout/plain-layout/PlainLayout";
import useMediaQuery from "@vahak/core-ui/dist/hooks/useMediaQuery";
import { UserScreenId } from "@vahak/core/dist/constants/userScreenId";

export function getlayoutForPage({ Component, pageProps }: any) {
    let getLayout: any;

    const isMobileScreen = useMediaQuery({ queryType: "mobile" });

    switch (pageProps.route) {
        // case HOME_ROUTE:
        //     getLayout = (page: any) => <SideNavLayoutWrapper>{page}</SideNavLayoutWrapper>;
        //     break;

        case HOME_ROUTE:
        case MARKETPLACE_ROUTE:
        case TRANSPORT_DIRECTORY:
            getLayout = (page: any) => (
                <DefaultLayoutWrapper
                    navContent={<DashboardNav mktType={pageProps.marketplaceType} />}
                    pageRoute={pageProps.route}
                >
                    {page}
                </DefaultLayoutWrapper>
            );
            break;

        case USER_PROFILE_PAGE:
            getLayout = (page: any) => (
                <PrivateRoute>
                    <DefaultLayoutWrapper navContent={<DashboardNav />}>{page}</DefaultLayoutWrapper>
                </PrivateRoute>
            );
            break;

        case MY_LORRIES_PAGE:
        case "new":
            // Temp changes, check with @sudhanshu for layout
            getLayout = (page: any) => (
                <PrivateRoute>
                    {!isMobileScreen ? (
                        <DefaultLayoutWrapper navContent={<DashboardNav />}>{page}</DefaultLayoutWrapper>
                    ) : (
                        <PlainLayout page={page} />
                    )}
                </PrivateRoute>
            );
            break;

        case DASHBOARD_ROUTE:
        case LISTING_PAGE:
        case BANK_DETAILS:
            getLayout = (page: any) => (
                <PrivateRoute>
                    <DefaultLayoutWrapper navContent={<DashboardNav mktType={pageProps.activeMarketType} />}>
                        {page}
                    </DefaultLayoutWrapper>
                </PrivateRoute>
            );
            break;

        case BULK_LOADS_LIST:
            getLayout = (page: any) => (
                <PrivatePremiumRoute>
                    <DefaultLayoutWrapper
                        navContent={<DashboardNav navbarName={NavBarName.PREMIUM_LOADS} showPostingIcon={false} />}
                    >
                        {page}
                    </DefaultLayoutWrapper>
                </PrivatePremiumRoute>
            );
            break;

        case PREMIUM_LOAD_TRIP:
        case TRACK_VEHICLE:
            getLayout = (page: any) => (
                <PrivatePremiumRoute>
                    <DefaultLayoutWrapper
                        navContent={<DashboardNav navbarName={NavBarName.PREMIUM_LOADS} showPostingIcon={false} />}
                    >
                        {page}
                    </DefaultLayoutWrapper>
                </PrivatePremiumRoute>
            );
            break;

        case MEMBERSHIP_UPGRADE: {
            getLayout = (page: any) => (
                <MembershipLayoutWrapper
                    navContent={<DashboardNav mktType={pageProps.marketplaceType} />}
                    pageRoute={pageProps.route}
                >
                    {page}
                </MembershipLayoutWrapper>
            );

            break;
        }

        case MEMBERSHIP:
        case MEMBERSHIP_HISTORY: {
            getLayout = (page: any) => (
                <PrivateRoute>
                    <MembershipLayoutWrapper
                        navContent={<DashboardNav mktType={pageProps.marketplaceType} />}
                        pageRoute={pageProps.route}
                    >
                        {page}
                    </MembershipLayoutWrapper>
                </PrivateRoute>
            );
            break;
        }

        case MEMBERSHIP_PAYMENT:
        case MEMBERSHIP_FEATURE:
        case UserScreenId.payment:
            getLayout = (page: any) => (
                <PrivateRoute>
                    <PlainLayout page={page} />
                </PrivateRoute>
            );
            break;

        case MOBILE_APP:
            getLayout = (page: any) => <MobileAppWebViewLayout page={page} />;
            break;
        case PAYMENT_LIST:
            getLayout = (page: any) => (
                <PrivatePremiumRoute>
                    <DefaultLayoutWrapper
                        navContent={<DashboardNav navbarName={NavBarName.PREMIUM_LOADS} showPostingIcon={false} />}
                    >
                        {page}
                    </DefaultLayoutWrapper>
                </PrivatePremiumRoute>
            );
            break;
        default:
            getLayout = Component.getLayout ?? ((page: any) => <DefaultTopNavLayout>{page}</DefaultTopNavLayout>);
            break;
    }
    return getLayout;
}
