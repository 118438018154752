import { generateObjectFromKeys } from "@vahak/core-ui/dist/methods";

const listOfKeys = ["ticketVasCargo", "ticketVasGps"] as const;

export const ADMIN_OPERATION = {
    blockUnblockUser: "blockUnblockUser",
    editOperations: "editOperations",
    editRoles: "editRoles",
    paymentGetTransactionsList: "paymentGetTransactionsList",
    profileEdit: "profileEdit",
    profileView: "profileView",
    readOperations: "readOperations",
    readRoles: "readRoles",
    tagAgent: "tagAgent",
    tagAgentManagerUI: "tagAgentManagerUI",
    tagAgentTsoLoad: "tagAgentTsoLoad",
    tagAgentTsoLorry: "tagAgentTsoLorry",
    ticketAadhar: "ticketAadhar",
    ticketAssuredLoad: "ticketAssuredLoad",
    ticketBidCancellation: "ticketBidCancellation",
    ticketCallMeBack: "ticketCallMeBack",
    ticketEway: "ticketEway",
    ticketGST: "ticketGST",
    ticketLoadVerification: "ticketLoadVerification",
    ticketPAN: "ticketPAN",
    ticketPhysicalVerification: "ticketPhysicalVerification",
    ticketRC: "ticketRC",
    ticketRefundRequest: "ticketRefundRequest",
    ticketVerifiedLoad1: "ticketVerifiedLoad1",
    postPlacementAdminView: "postPlacementAdminView",
    postPlacementView: "postPlacementView",
    userLoadLorryAction: "userLoadLorryAction",
    userPhoneNumberVisibilityUI: "userPhoneNumberVisibilityUI",
    tsoBulkTagging: "tsoBulkTagging",
    paymentReports: "paymentReports",
    metaBaseReport: "metaBaseReport",
    ...generateObjectFromKeys(listOfKeys)
};

export const ADMIN_OPERATION_LIST = Object.keys(ADMIN_OPERATION);
