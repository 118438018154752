import React from "react";
import Styles from "./LoginFormNew.module.scss";
import ModalOrBottomSheetWrapper from "@vahak/core-ui/dist/components/ModalOrBottomSheetWrapper";
import Flex from "@vahak/core-ui/dist/layout/Flex";
import LeftSideSection from "./left-side-section/LeftSideSection";
import RightSideSection from "./right-side-section/RightSideSection";
import useMediaQuery from "@vahak/core-ui/dist/hooks/useMediaQuery";
import { useRouter } from "next/router";
import { IDs } from "@vahak/core/dist/constants";

interface LoginFormNewProps {}

const LoginFormNew = ({}: LoginFormNewProps) => {
    const isMobileScreen = useMediaQuery({ queryType: "mobile" });
    const router = useRouter();

    const isLoginModalOpen = router.asPath.split("#")[1]?.includes("Login");

    return (
        <ModalOrBottomSheetWrapper
            isOpen={isLoginModalOpen}
            showHeader={false}
            borderRadius={24}
            paddedModal={false}
            sheetBorderRadius={16}
            modalSize="fit"
            sheetBodyMinHeight={"500px"}
            id={IDs.auth.loginModal}
            disableSheetSwipe
            disableSheetOverlayClick
        >
            <Flex>
                {!isMobileScreen ? <LeftSideSection /> : <></>}
                <RightSideSection />
            </Flex>
        </ModalOrBottomSheetWrapper>
    );
};

export default LoginFormNew;
