import classNames from "classnames";
import React, { ReactNode } from "react";
import { CommonLayoutProps } from "../layout-types";

import Styles from "./BottomNavLayout.module.scss";

export interface BottomNavLayoutProps extends CommonLayoutProps {
    topNavContent?: ReactNode;
}

const BottomNavLayout = ({
    topNavContent,
    content: Content,
    children,
    navContent,
    className
}: BottomNavLayoutProps) => {
    return (
        <div className={classNames(className)}>
            {topNavContent}
            <div className={classNames(topNavContent ? Styles.bodyContent : "")}>
                {Content ? <Content /> : children}
            </div>
            <nav className={classNames(Styles.bottomNavbarWrapper)}>{navContent}</nav>
        </div>
    );
};

export default BottomNavLayout;
