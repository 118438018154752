import { LORRY_BODY_TYPE, NEW_LORRY_TYPES } from "@vahak/core/dist/constants/lorryTypes";
import { PlaceSearchResult } from "@vahak/core/dist/custom-types/location-search";
import { CompanyRouteType } from "@vahak/core/dist/custom-types/user";
import { generateFormFieldNames } from "../../../utils/form";
import { LORRY_STATUS } from "@vahak/core/dist/constants/lorryStatus";

// Lorry posting
export enum LORRY_POSTING_FORM_LABELS {
    VEHICLE_DETAILS = "Vehicle Details",
    VEHICLE_TYPE = "Vehicle Type",
    LORRY_POSTED_SUCCESSFULLY = "Lorry Posted Successfully"
}

export enum LORRY_CREATION_FORM_IDS {
    VEHICLE_NUMBER = "vehicle_number",
    VEHICLE_LOCATION = "vehicle_location",
    VERIFY_VEHICLE = "verify_vehicle",
    VERIFY_VEHICLE_FAILED = "verify_vehicle_failed",
    VEHICLE_INFO = "vehicle_info",
    VEHICLE_TYPE = "vehicle_type",
    VEHICLE_INFO_AND_VEHICLE_TYPE = "vehicle_info_and_vehicle_type",
    LORRY_POSTED_SUCCESSFULLY = "lorry_posted_successfully"
}

export enum LORRY_CREATION_FORM_GROUP {
    VEHICLE_DETAILS = "Vehicle Details"
}

export interface verifyVehicleFlag {
    yesThisIsMyVehicle?: boolean;
    manualVerification?: boolean;
    showCapacityField?: boolean;
}

export interface lorryFieldType {
    vehicleBody: number | null; // used only for internal purpose
    lorryType: number;
    isLorryCapacityRequired: boolean;
    lorryCapacity: number;
    lorryTyres: number;
    lorrySize: number;
    lorryBodyType: LORRY_BODY_TYPE | null;
    sizeText: string;
    showCapacityField?: boolean;
    formSource?: "load" | "lorry";
    vehicleCategory?: string;
}

export interface lorryCreationFormFieldsTypes extends lorryFieldType, verifyVehicleFlag {
    lorryNum: string;
    currentCityPlaceId: string;
    currentCityText: string;
    currentCityOption: PlaceSearchResult | undefined;
    routeIds: CompanyRouteType[];
    rcDocument: File | undefined;
    status?: LORRY_STATUS;
    rcExpired?: boolean;
    isRcUploadRequired?: boolean;
    formType?: "post" | "edit";
}

export interface editBulkLoadLorryDetails extends lorryFieldType {
    id: number;
    lorryTableId: number;
    rcExpired?: boolean;
}

export const initialLorryFieldFormValues: lorryFieldType = {
    vehicleBody: null,
    lorryType: 0,
    isLorryCapacityRequired: true,
    lorryCapacity: 0,
    lorryBodyType: null,
    lorrySize: 0,
    lorryTyres: 0,
    sizeText: "",
    showCapacityField: false
};

export const commonLorryFormFieldNames = generateFormFieldNames(initialLorryFieldFormValues);

export const initialLorryCreationFormValues: lorryCreationFormFieldsTypes = {
    lorryNum: "",
    currentCityPlaceId: "",
    currentCityText: "",
    currentCityOption: undefined,
    routeIds: [],
    rcDocument: undefined,
    rcExpired: false,
    isRcUploadRequired: false,
    ...initialLorryFieldFormValues
};

export const lorryCreationFormFieldNames = generateFormFieldNames(initialLorryCreationFormValues);

// Load posting
export enum LOAD_POSTING_FORM_LABELS {
    LOAD_DETAILS = "Load Details",
    VEHICLE_TYPE = "Vehicle Details",
    PAYMENT_DETAILS = "Payment Details"
}

export enum LOAD_CREATION_FORM_IDS {
    LOAD_LOCATION_DETAILS = "load_locations_details",
    LOAD_INFO = "load_info",
    VEHICLE_TYPE = "vehicle_type",
    PAYMENT_DETAILS = "payment_details",
    ADVANCE_AMOUNT = "advance_amount",
    LOAD_POSTED_SUCCESSFULLY = "load_post_success"
}

export enum LOAD_CREATION_FORM_GROUP {
    LOAD_DETAILS = "Location & Material details",
    PAYMENT_DETAILS = "Payment Details"
}

interface loadCreationFormMetaInfo {
    isExpectedAmountMandatory?: boolean;
    postedTime: string | undefined;
    savedSrcPlaceInfo: PlaceSearchResult | undefined;
    savedDestPlaceInfo: PlaceSearchResult | undefined;
    recommendedPriceMin?: number | undefined;
    recommendedPriceMax?: number | undefined;
    rcExpired?: boolean;
    selectedLorryType?: typeof NEW_LORRY_TYPES[number];
    isSpotLoad?: boolean;
    isTapNgoLoad?: boolean;
    loadGhostingPenaltyDeduction?: boolean;
}

export interface loadCreationFormFieldsTypes extends lorryFieldType, loadCreationFormMetaInfo {
    srcPlaceId: string;
    destPlaceId: string;
    srcText: string;
    destText: string;
    material: string;
    quantity: number | undefined;
    quantityType: string;
    expectedAmount: number | undefined;
    isNegotiable: boolean;
    paymentType: 0 | 1 | undefined;
    isFixedPrice: boolean;
    advanceAmount: number | undefined;
    remarks: string;
    is_odc: boolean;
    length: number | undefined;
    breadth: number | undefined;
    height: number | undefined;
    loadingTimestamp: number | undefined;
    includesLoadingCharges: boolean | undefined;
    loadingCharges: number | undefined;
    source_id: number | undefined;
    destination_id: number | undefined;
}

export const initialLoadCreationFormValues: loadCreationFormFieldsTypes = {
    ...initialLorryFieldFormValues,
    srcPlaceId: "",
    destPlaceId: "",
    srcText: "",
    destText: "",
    material: "",
    quantity: undefined,
    quantityType: "Tonne(s)",
    expectedAmount: undefined,
    isNegotiable: false,
    paymentType: 1,
    isFixedPrice: true,
    advanceAmount: undefined,
    remarks: "",
    is_odc: false,
    length: undefined,
    breadth: undefined,
    height: undefined,
    loadingTimestamp: undefined,
    postedTime: undefined,
    isExpectedAmountMandatory: false,
    savedDestPlaceInfo: undefined,
    savedSrcPlaceInfo: undefined,
    recommendedPriceMin: undefined,
    recommendedPriceMax: undefined,
    rcExpired: false,
    includesLoadingCharges: true,
    loadingCharges: undefined,
    isLorryCapacityRequired: false,
    source_id: undefined,
    destination_id: undefined,
    isSpotLoad: false,
    isTapNgoLoad: false,
    loadGhostingPenaltyDeduction: false
};

export const loadCreationFormFieldNames = generateFormFieldNames(initialLoadCreationFormValues);

export interface EditLorryTypes {
    values: lorryCreationFormFieldsTypes;
    id: number;
}
export interface EditLoadTypes {
    values: loadCreationFormFieldsTypes;
    id: number;
}
