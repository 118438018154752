import React, { useContext, useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import { Cookies } from "react-cookie";

import { COOKIES } from "@vahak/core/dist/constants/misc";
import { useValidateToken } from "@vahak/core/dist/_services/hooks/useAuthService";
import { QueryNames } from "@vahak/core/dist/_services/hooks/queryNames";
import { useQueryClient } from "react-query";
import { AppContext } from "@vahak/core/dist/app-context";
import { handleLogout } from "@vahak/core/dist/methods/handleLogout";
import { removeAuthToken } from "@vahak/core/dist/_services/utils/removeAuthToken";
import { ClearAppCookiesAndLogoutFlutterPage } from "../components/mobile-app/mobileAppFunctions";

interface Props {}

const AuthVerifyComponent = (props: Props) => {
    const router = useRouter();

    const {
        setCompanyId,
        setIsPhoneVerified,
        setName,
        setLang,
        setCompanyLogo,
        setUserId,
        phoneNum,
        setCsDashboardUserRoles,
        setCustomerCompanyId,
        setCustomerName,
        setCustomerPhone,
        setShowBankAddPopup,
        setSessionId,
        setThirdPartyCompanyName
    } = useContext(AppContext);

    const [validateQueryEnabled, setValidateQueryEnabled] = useState(false);

    const prevPathNameRef = useRef<string | null>(null);

    const cookies = new Cookies();
    const token = cookies.get(COOKIES.token);

    const queryClient = useQueryClient();

    const { data: tokenStatus } = useValidateToken({
        token,
        enabled: validateQueryEnabled
        // refetchInterval: 3600 * 1000
    });

    useEffect(() => {
        setValidateQueryEnabled(!!token);
    }, [token]);

    useEffect(() => {
        if (!token || tokenStatus === true) {
            return;
        }
        if (tokenStatus === false) {
            /**
             * TODO (gowdham): update handleLogout method with latest code from master
             */
            removeAuthToken();
            handleLogout({
                setCompanyId,
                setIsPhoneVerified,
                setName,
                setLang,
                setCompanyLogo,
                setUserId,
                phoneNum: Number(phoneNum),
                setCsDashboardUserRoles,
                setCustomerCompanyId,
                setCustomerName,
                setCustomerPhone,
                setShowBankAddPopup,
                setSessionId,
                setThirdPartyCompanyName
            });
            ClearAppCookiesAndLogoutFlutterPage();
        }
    }, [tokenStatus]);

    useEffect(() => {
        const handleRouteChange = () => {
            if (prevPathNameRef.current === router.pathname) {
                return;
            }
            queryClient.invalidateQueries(QueryNames.validateToken);
            prevPathNameRef.current = router.pathname;
        };

        router.events.on("routeChangeStart", handleRouteChange);

        // If the component is unmounted, unsubscribe
        // from the event with the `off` method:
        return () => {
            router.events.off("routeChangeStart", handleRouteChange);
        };
    }, []);
    return <></>;
};

export default AuthVerifyComponent;
