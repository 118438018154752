import { COOKIES, DefaultCookieOptions } from "@vahak/core/dist/constants/misc";
import { Cookies } from "react-cookie";
import { AB_TEST_CAMPAIGN_IDS } from "../constants/abTestVariations";

const getSavedABTest = async () => {
    const cookies = new Cookies();
    const savedABTestString = await cookies.get(COOKIES.abTest);
    if (!savedABTestString) {
        return {};
    }
    return JSON.parse(savedABTestString);
};

const getABTestVariation = async (campaign: any) => {
    const cookies = new Cookies();
    let abTestCookieData = await getSavedABTest();
    let currentCampaignVariation = abTestCookieData[campaign];
    if (currentCampaignVariation) {
        return +currentCampaignVariation;
    } else {
        if (typeof window != "undefined" && window) {
            if (window.VWO && window.VWO.onVariationApplied) {
                window.VWO.onVariationApplied((c: any) => {
                    // c= [ , campaign, variation]
                    if (c[1] == campaign) {
                        abTestCookieData = { ...abTestCookieData, [campaign]: c[2] };
                        cookies.set(COOKIES.abTest, JSON.stringify(abTestCookieData), DefaultCookieOptions);
                        currentCampaignVariation = c[2];
                    }
                });
            }
        }
        return +currentCampaignVariation;
    }
};

export const getVwoABTestVariation = (campaign: any) => {
    const cookies = new Cookies();
    const abTestVariation =
        cookies.get(`_vis_opt_exp_${campaign}_combi`) || cookies.get(`_vis_opt_exp_${campaign}_combi_choose`);
    if (abTestVariation) {
        return +abTestVariation;
    }
    return null;
};

export const getAllVwoABTestVariations = () => {
    const cookies = new Cookies();
    let abTestVariations: any = {};
    for (const campaign in AB_TEST_CAMPAIGN_IDS) {
        const variation =
            cookies.get(`_vis_opt_exp_${AB_TEST_CAMPAIGN_IDS[campaign]}_combi`) ||
            cookies.get(`_vis_opt_exp_${AB_TEST_CAMPAIGN_IDS[campaign]}_combi_choose`);
        abTestVariations[AB_TEST_CAMPAIGN_IDS[campaign]] = +variation;
    }
    return { abTestVariations };
};
