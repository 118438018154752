import { useContext } from "react";
import { useRouter } from "next/router";
import { AppContext } from "@vahak/core/dist/app-context";

//Constants
import { ROUTES } from "@vahak/core/dist/constants/routeStrings";
import { MY_LOAD_LORRY_TABS } from "@vahak/core/dist/constants/tab";
import { GA4EventNames } from "@vahak/core/dist/constants/event-names";

//Methods

//Types
import { MarketType } from "@vahak/core/dist/custom-types/market-type";

//Icons
import LoadListingDisabledIcon from "@vahak/core/dist/icons/load_listing_disabled.svg";
import LoadListingEnabledIcon from "@vahak/core/dist/icons/load_listing_white.svg";
import LorryListingActiveIcon from "@vahak/core/dist/icons/my_lorries_active.svg";
import LorryListingInactiveIcon from "@vahak/core/dist/icons/my_lorries_inactive.svg";
import MarketplaceNavActiveIcon from "@vahak/core/dist/icons/MarketplaceNavActiveIcon.svg";
import MarketplaceNavDeadIcon from "@vahak/core/dist/icons/MarketplaceNavDeadIcon.svg";
import HomeEnabledIcon from "@vahak/core/dist/icons/home-icon.svg";
import HomeDisabledIcon from "@vahak/core/dist/icons/home-icon-disabled.svg";
import TransporterDirectoryEnabled from "@vahak/core/dist/icons/transporter-directory-active.svg";
import TransporterDirectoryDisabled from "@vahak/core/dist/icons/transporter-directory-disabled.svg";
import RightArrowWhite from "@vahak/core/dist/icons/AngleLeftWhite.svg";
import ServicePageActive from "@vahak/core/dist/icons/service-page-active-icon.svg";
import ServicePageDisabled from "@vahak/core/dist/icons/service-page-icon.svg";
import MeetVahakPageActive from "@vahak/core/dist/icons/meet-vahak-page-active-icon.svg";
import MeetVahakPageDisabled from "@vahak/core/dist/icons/meet-vahak-page-icon.svg";
import ResourcePageActive from "@vahak/core/dist/icons/resource-page-active-icon.svg";
import ResourcePageDisabled from "@vahak/core/dist/icons/resource-page-icon.svg";
import MembershipCrownIcon from "@vahak/core/dist/icons/MembershipCrown.svg";
import MembershipCrownDisabledIcon from "@vahak/core/dist/icons/MembershipCrownDisabled.svg";
import { useFeatureByServiceType } from "../../../hooks/useFeatureByServiceType";

export interface NavItemsType {
    id: string;
    label?: string;
    Icon?: string;
    IconActive?: string;
    pathName: string;
    alternativePath?: string;
    SubMenuToggleIcon?: string;
    subMenu?: SubMenuType[];
    clientAppEventName?: string;
}

interface SubMenuType {
    id: string;
    label: string;
    Icon?: string;
    IconActive?: string;
    pathName: string;
    nestedDirectories?: string[];
    SubMenuToggleIcon?: string;
    subMenu?: SubMenuType[];
    clientAppEventName?: string;
}

export enum ClientAppMenuId {
    home = "home",
    myLoads = "myLoads",
    myLories = "myLories",
    marketplace = "marketplace",
    transporter = "transporter",
    servicePages = "servicePages",
    meetVahak = "meetVahak",
    resource = "resource",
    membership = "membership"
}

export function useGetLayoutOptions() {
    const router = useRouter();

    const { showLoadFeatures, showLorryFeatures, ShowLoadPost, ShowLorryPost } = useFeatureByServiceType();
    const { haveMembership } = useContext(AppContext);

    let NavItems: NavItemsType[] = [];

    NavItems.push(
        {
            id: ClientAppMenuId.home,
            Icon: HomeDisabledIcon,
            IconActive: HomeEnabledIcon,
            label: "Home",
            pathName: "/dashboard",
            clientAppEventName: GA4EventNames.SIDE_NAV.homepage_icon_clicked
        },
        {
            id: ClientAppMenuId.myLoads,
            Icon: LoadListingDisabledIcon,
            IconActive: LoadListingEnabledIcon,
            label: "My Loads",
            pathName: router.asPath.includes("my-loads")
                ? router.asPath.includes("bids")
                    ? ROUTES.myLoadsMyLorries.getUrl("loads", MY_LOAD_LORRY_TABS.BIDS)
                    : ROUTES.myLoadsMyLorries.getUrl("loads", MY_LOAD_LORRY_TABS.LISTING)
                : ROUTES.myLoadsMyLorries.getUrl("loads", MY_LOAD_LORRY_TABS.LISTING),
            clientAppEventName: GA4EventNames.SIDE_NAV.my_load_icon_clicked
        },
        {
            id: ClientAppMenuId.myLories,
            Icon: LorryListingInactiveIcon,
            IconActive: LorryListingActiveIcon,
            label: "My Lorries",
            pathName: router.asPath.includes("my-lorries")
                ? router.asPath.includes("bids")
                    ? ROUTES.myLoadsMyLorries.getUrl("lorries", MY_LOAD_LORRY_TABS.BIDS)
                    : ROUTES.myLoadsMyLorries.getUrl("lorries", MY_LOAD_LORRY_TABS.LISTING)
                : ROUTES.myLoadsMyLorries.getUrl("lorries", MY_LOAD_LORRY_TABS.LISTING),
            clientAppEventName: GA4EventNames.SIDE_NAV.my_lorry_icon_clicked
        }
    );

    /**
     * This condition is to check if the current user is Shipper service type
     * If yes then remove My lorries from side nav
     */
    if (ShowLoadPost && !ShowLorryPost) {
        NavItems.splice(2, 1);
    }
    /**
     * This condition is to check if the current user is Fleet owner service type
     * If yes then remove My loads from side nav
     */
    if (ShowLorryPost && !ShowLoadPost) {
        NavItems.splice(1, 1);
    }

    NavItems.push(
        {
            id: ClientAppMenuId.marketplace,
            Icon: MarketplaceNavDeadIcon,
            IconActive: MarketplaceNavActiveIcon,
            label: "Marketplace",
            pathName:
                !showLoadFeatures && showLorryFeatures
                    ? ROUTES.marketplace.getUrl("lorry")
                    : ROUTES.marketplace.getUrl((router?.query?.params?.[0] as MarketType) || "load"),
            clientAppEventName: GA4EventNames.SIDE_NAV.marketplace_icon_clicked
        },
        {
            id: ClientAppMenuId.transporter,
            Icon: TransporterDirectoryDisabled,
            IconActive: TransporterDirectoryEnabled,
            label: "Transport Directory",
            pathName: ROUTES.transporters.getUrl(),
            clientAppEventName: GA4EventNames.SIDE_NAV.transport_directory_clicked
        },
        {
            id: ClientAppMenuId.servicePages,
            Icon: ServicePageDisabled,
            IconActive: ServicePageActive,
            label: "Services",
            pathName: "",
            clientAppEventName: GA4EventNames.SIDE_NAV.services_icon_clicked,
            SubMenuToggleIcon: RightArrowWhite,
            subMenu: [
                {
                    id: "online-load-booking",
                    Icon: "",
                    IconActive: "",
                    label: "Online Load Booking",
                    pathName: "/online-load-booking"
                },
                {
                    id: "online-lorry-booking",
                    Icon: "",
                    IconActive: "",
                    label: "Online Lorry Booking",
                    pathName: "/online-truck-booking"
                },
                {
                    id: "grow-network",
                    Icon: "",
                    IconActive: "",
                    label: "Grow Network",
                    pathName: "/grow-network"
                },
                {
                    id: "logistics-services",
                    Icon: "",
                    IconActive: "",
                    label: "Logistics Services",
                    pathName: "/logistics-services"
                },
                {
                    id: "full-truck-load",
                    Icon: "",
                    IconActive: "",
                    label: "Full Truck Load",
                    pathName: "/full-truck-load"
                },
                {
                    id: "part-load-booking",
                    Icon: "",
                    IconActive: "",
                    label: "Part Load Booking",
                    pathName: "/part-load-booking"
                }
                // {
                //     id: "cargo-insurance",
                //     Icon: "",
                //     IconActive: "",
                //     label: "Cargo Insurance",
                //     pathName: "/vas/cargo-insurance"
                // }
                // {
                //     id: "commercial-vehicle-insurance",
                //     Icon: "",
                //     IconActive: "",
                //     label: "Commercial Vehicle Insurance",
                //     pathName: "/vas/commercial-vehicle-insurance"
                // }
            ]
        },
        {
            id: ClientAppMenuId.meetVahak,
            Icon: MeetVahakPageDisabled,
            IconActive: MeetVahakPageActive,
            label: "Meet Vahak",
            pathName: "",
            clientAppEventName: GA4EventNames.SIDE_NAV.meet_vahak_icon_clicked,
            SubMenuToggleIcon: RightArrowWhite,
            subMenu: [
                {
                    id: "about-us",
                    Icon: "",
                    IconActive: "",
                    label: "About Us",
                    pathName: "/about-us"
                },
                {
                    id: "how-vahak-helps",
                    Icon: "",
                    IconActive: "",
                    label: "How Vahak Helps",
                    pathName: "/how-vahak-helps"
                },
                {
                    id: "why-choose-us",
                    Icon: "",
                    IconActive: "",
                    label: "Why Choose Us",
                    pathName: "/why-choose-us"
                },
                {
                    id: "careers",
                    Icon: "",
                    IconActive: "",
                    label: "Careers",
                    pathName: "/careers"
                },
                {
                    id: "contact-us",
                    Icon: "",
                    IconActive: "",
                    label: "Contact Us",
                    pathName: "/contact-us"
                }
            ]
        },
        {
            id: ClientAppMenuId.resource,
            Icon: ResourcePageDisabled,
            IconActive: ResourcePageActive,
            label: "Resources",
            pathName: "",
            clientAppEventName: GA4EventNames.SIDE_NAV.resource_icon_clicked,
            SubMenuToggleIcon: RightArrowWhite,
            subMenu: [
                {
                    id: "blogs",
                    Icon: "",
                    IconActive: "",
                    label: "Blogs",
                    pathName: "/blogs"
                },
                {
                    id: "glossary",
                    Icon: "",
                    IconActive: "",
                    label: "Glossary",
                    pathName: "/glossary"
                }
            ]
        },
        {
            id: ClientAppMenuId.membership,
            Icon: MembershipCrownDisabledIcon,
            IconActive: MembershipCrownIcon,
            label: haveMembership ? "Manage Membership" : "Upgrade Membership",
            pathName: haveMembership ? ROUTES.membership.getUrl() : ROUTES.membershipUpgrade.getUrl(),
            clientAppEventName: GA4EventNames.SIDE_NAV.manage_membership_clicked
        }
        // {
        //     id: "more",
        //     Icon: TransporterDirectoryDisabled,
        //     IconActive: TransporterDirectoryEnabled,
        //     SubMenuToggleIcon: RightArrowWhite,
        //     label: "More",
        //     pathName: "",
        //     subMenu: [
        //         {
        //             id: "service-pages",
        //             Icon: TransporterDirectoryDisabled,
        //             IconActive: TransporterDirectoryEnabled,
        //             label: "Services",
        //             pathName: "",
        //             SubMenuToggleIcon: RightArrowWhite,
        //             subMenu: [
        //                 {
        //                     id: "online-load-booking",
        //                     Icon: TransporterDirectoryDisabled,
        //                     IconActive: TransporterDirectoryEnabled,
        //                     label: "Online Load Booking",
        //                     pathName: "/online-load-booking"
        //                 }
        //             ]
        //         }
        //     ]
        // }
    );

    //     NavItems.push({
    //         id: "paymentListing",
    //         Icon: <PaymentsDisabledIcon />,
    //         IconActive: <PaymentsEnabledIcon />,
    //         label: "Payment List",
    //         pathName: "/premium/payment-list"
    //     });
    // }

    let navMenuOption: NavItemsType[];

    /**
     * Temp condition to render trackscreen in mobile app
     * Flutter app layout option
     */
    const mwLayout = router.pathname.includes("mw");

    switch (true) {
        case mwLayout:
            navMenuOption = NavItems;
            break;
        default:
            //TODO: GOWDHAM Check this
            navMenuOption = NavItems;
            break;
    }

    const checkSubMenu = (subMenu: SubMenuType[]) => {
        return subMenu?.find((sub) => {
            router.asPath.includes(sub.pathName)
                ? router.asPath.includes(sub.pathName)
                : sub.nestedDirectories?.length
                ? sub.nestedDirectories.some((d) => router.asPath.includes(d))
                : "";
        });
    };

    const activeTab = navMenuOption.find((e) => {
        let ROUTE_PATHNAME = router.pathname;
        //Jugaad

        if (router.pathname?.includes("/membership") && e?.id === "membership") {
            return true;
        }

        if (router.pathname.includes("marketplace")) {
            ROUTE_PATHNAME = `/marketplace/${router?.query?.params?.[0] as MarketType}`;
        }

        if (router.pathname.includes("transporters")) {
            ROUTE_PATHNAME = `/transporters`;
        }

        if (router.pathname === "/web" || router.pathname === "/mob") {
            ROUTE_PATHNAME = `/`;
        }

        const removedWebAndMobFromRoute = ROUTE_PATHNAME.replace("/web", "").replace("/mob", "");

        return e.subMenu?.length ? checkSubMenu(e.subMenu) : e.pathName === removedWebAndMobFromRoute;
    });

    const activeSubMenu = activeTab?.subMenu?.length ? checkSubMenu(activeTab.subMenu) : undefined;

    return { navMenuOption, activeMenu: { menuId: activeTab?.id, subMenuId: activeSubMenu?.id } };
}
