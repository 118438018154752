import { useState, useEffect } from "react";

interface SuccessResponse {
    razorpay_signature: string;
    razorpay_order_id: string;
    razorpay_payment_id: string;
}
interface ErrorResponse {
    error: {
        code: string;
        description: string;
        field: null | string;
        source: string;
        step: string;
        reason: string;
        metadata: {
            payment_id: string;
            order_id: string;
        };
    };
}

export interface Options {
    key: string;
    amount: number;
    currency: string;
    name: string;
    description?: string;
    image?: string;
    order_id: string;
    handler?: (response: SuccessResponse) => void;
    prefill?: {
        name?: string;
        email?: string;
        contact?: string;
        method?: "card" | "netbanking" | "wallet" | "emi" | "upi";
    };
    notes?: {};
    theme?: {
        hide_topbar?: boolean;
        color?: string;
        backdrop_color?: string;
    };
    modal?: {
        backdropclose?: boolean;
        escape?: boolean;
        handleback?: boolean;
        confirm_close?: boolean;
        ondismiss?: () => void;
        animation?: boolean;
    };
    subscription_id?: string;
    subscription_card_change?: boolean;
    recurring?: boolean;
    callback_url?: string;
    redirect?: boolean;
    customer_id?: string;
    timeout?: number;
    remember_customer?: boolean;
    readonly?: {
        contact?: boolean;
        email?: boolean;
        name?: boolean;
    };
    hidden?: {
        contact?: boolean;
        email?: boolean;
    };
    send_sms_hash?: boolean;
    allow_rotation?: boolean;
    retry?: {
        enabled?: boolean;
        max_count?: boolean;
    };
    config?: {
        display: {
            language: "en" | "ben" | "hi" | "mar" | "guj" | "tam" | "tel";
        };
    };
}

export interface PaymentOptions {
    onSuccess: Options["handler"];
    onError: (response: ErrorResponse) => void;
    onDismiss: () => void;
    order_id: string;
    amount: number;
}

const useRazorpay = () => {
    const [paymentId, setPaymentId] = useState<string | null>(null);

    const loadRazorpayScript = () => {
        return new Promise<void>((resolve) => {
            const script = document.createElement("script");
            script.src = "https://checkout.razorpay.com/v1/checkout.js";
            script.async = true;
            script.onload = () => {
                resolve();
            };
            document.body.appendChild(script);
        });
    };

    useEffect(() => {
        loadRazorpayScript();
    }, []);

    const initializePayment = (params: PaymentOptions) => {
        const options = {
            key: process.env.NEXT_PUBLIC_RAZORPAY_KEY,
            currency: "INR",
            amount: params.amount * 100,
            order_id: params.order_id,
            handler: (response: SuccessResponse) => {
                setPaymentId(response.razorpay_payment_id);
                params?.onSuccess?.(response);
            },
            modal: {
                ondismiss: params.onDismiss
            }
        } as Options;

        const razorpay = new (window as any).Razorpay(options);
        razorpay.on("payment.failed", params.onError);
        razorpay.open();
    };

    return {
        paymentId,
        initializePayment
    };
};

export default useRazorpay;
