import React, { useContext } from "react";
import { useRouter } from "next/router";
import classnames from "classnames";

//Style
import Styles from "./ProfileItemPopupMenu.module.scss";

//Context
import { AppContext } from "@vahak/core/dist/app-context";

//component
import Dropdown from "@vahak/core/dist/components/dropdown/Dropdown";
import AvatarImage from "../../../utils/avatar-image/AvatarImage";
import Flex from "@vahak/core-ui/dist/layout/Flex";
import Typography from "@vahak/core-ui/dist/components/Typography";
import BottomSheet from "@vahak/core-ui/dist/components/BottomSheet/BottomSheet";
import Button from "@vahak/core-ui/dist/components/Button/Button";

//Constant
import COLORS from "@vahak/core-ui/dist/constants/colors";
import { IDs } from "@vahak/core/dist/constants/automation";

import { ROUTES } from "@vahak/core/dist/constants/routeStrings";

import { ADMIN_OPERATION } from "../../../../constants/adminOperation";

//methods
import { handleLogout } from "@vahak/core/dist/methods/handleLogout";
import { moEngageUserLogOut } from "@vahak/core/dist/_services/user-analytics.service";
import IfCanAccess from "../../../admin/IfCanAccess/IfCanAccess";

//Hooks
import { useToggleModalWithLocalState } from "@vahak/core-ui/dist/hooks";
import useMediaQuery from "@vahak/core-ui/dist/hooks/useMediaQuery";
import { useCsDashboard } from "@vahak/core/dist/hooks/useCsDashboard";

//Icon
import LogoutIcon from "@vahak/core/dist/standard-icons/Logout.svg";
import ProfileIcon from "@vahak/core/dist/standard-icons/profile.svg";

interface ProfileDropDownProps {
    onClickMenuCallBack?: () => void;
}

const ProfileItemPopupMenu = ({ onClickMenuCallBack }: ProfileDropDownProps) => {
    const {
        companyLogo,
        name,
        isPhoneVerified,
        companyId,
        setCompanyId,
        setIsPhoneVerified,
        setName,
        setLang,
        setCompanyLogo,
        setUserId,
        phoneNum,
        setCsDashboardUserRoles,
        setCustomerCompanyId,
        setCustomerName,
        setCustomerPhone,
        csDashboardUserRoles,
        setSessionId,
        fromFlutterApp,
        setThirdPartyCompanyName
    } = useContext(AppContext);
    const {
        isModalOpen: isDropDownOpen,
        openModal: openProfilePopup,
        toggleModal: toggleProfilePopup
    } = useToggleModalWithLocalState(false);
    const {
        isModalOpen: isUserProfileSheetVisible,
        openModal: openUserProfileSheetPopup,
        toggleModal: toggleUserProfileSheetPopup
    } = useToggleModalWithLocalState(false);

    const router = useRouter();

    const midScreen = useMediaQuery({ queryType: "medium-screen" });

    const isLoggedIn = Boolean(isPhoneVerified && companyId);

    const onClickLogout = async () => {
        handleLogout({
            setCompanyId,
            setIsPhoneVerified,
            setName,
            setLang,
            setCompanyLogo,
            setUserId,
            phoneNum: Number(phoneNum),
            setCsDashboardUserRoles,
            setCustomerCompanyId,
            setCustomerName,
            setCustomerPhone,
            setSessionId,
            setThirdPartyCompanyName
        });

        moEngageUserLogOut();
        if (router.pathname.includes("/marketplace")) {
            router.replace(router.asPath, undefined, { shallow: false });
        } else {
            router.replace(`${ROUTES.marketplace.getUrl("load")}`, undefined, { shallow: true, scroll: false });
        }
    };

    const handleAdminConsoleLogout = () => {
        handleLogout({
            setCompanyId,
            setIsPhoneVerified,
            setName,
            setLang,
            setCompanyLogo,
            setUserId,
            setCsDashboardUserRoles,
            setCustomerCompanyId,
            setCustomerPhone,
            setCustomerName,
            setSessionId
        });
    };

    const PROFILE_OPTIONS = [
        {
            id: IDs.profileDropDown.myProfileBtn,
            title: "My Profile",
            route: ROUTES.userProfile.getUrl(),
            handler: undefined,
            icon: <ProfileIcon />,
            wrapperClass: "",
            textColor: undefined,
            hide: !isLoggedIn
        },
        {
            id: IDs.profileDropDown.profileLogout,
            title: "Logout",
            route: "",
            handler: onClickLogout,
            icon: <LogoutIcon />,
            wrapperClass: Styles.logout,
            textColor: COLORS.RED,
            hide: undefined
        }
    ];
    const handleOptionClick = (route: string) => {
        onClickMenuCallBack?.();
        toggleProfilePopup();
        router.push(route, undefined, {
            shallow: true
        });
    };

    const { isCsDashboardUser } = useCsDashboard();

    const renderOptions = () => {
        return !isCsDashboardUser ? (
            <Flex padding={20} flexDirection="column">
                <Flex alignItems="center" gap={10}>
                    <AvatarImage imageUrl={companyLogo} alt={name || "Vahak User"} size="sm" />
                    <Typography weight="semibold" size="m">
                        {name || "Guest"}
                    </Typography>
                </Flex>
                <Flex className={classnames(Styles.profileOptions)} flexDirection="column" gap={20}>
                    {PROFILE_OPTIONS.map((option) => {
                        if (option.hide) return;
                        return (
                            <Flex
                                alignItems="center"
                                gap={10}
                                className={classnames(Styles.profileOption, option.wrapperClass)}
                                onClick={() => (option.handler ? option.handler() : handleOptionClick(option.route))}
                                id={option.id}
                            >
                                {option.icon}
                                <Typography
                                    weight="semibold"
                                    size="m"
                                    color={option.textColor ? option.textColor : COLORS.BLACK}
                                >
                                    {option.title}
                                </Typography>
                            </Flex>
                        );
                    })}
                </Flex>
            </Flex>
        ) : (
            <Flex padding={20} flexDirection="column" gap={10}>
                <Flex gap={10}>
                    <AvatarImage imageUrl={companyLogo} alt={name || "Vahak User"} size="sm" />
                    <Flex gap={0} flexDirection="column">
                        <Typography weight="semibold" size="m">
                            {name}
                        </Typography>
                        <Typography weight="semibold" size="sm" color={COLORS.GREY_500}>
                            {phoneNum}
                        </Typography>
                        <Typography weight="semibold" size="sm" color={COLORS.GREY_500} textTransform="uppercase">
                            {csDashboardUserRoles?.map((role) => role.replaceAll("_", " ")).join(", ")}
                        </Typography>
                    </Flex>
                </Flex>
                <Flex flexDirection="column" gap={20} padding={"20px 20px 0px 20px"}>
                    <IfCanAccess action={ADMIN_OPERATION.blockUnblockUser}>
                        <Flex alignItems="center" gap={10} className={classnames(Styles.profileOption)}>
                            <Button
                                fillType="text"
                                onClick={() => handleOptionClick(ROUTES.adminConsoleBlockUsers.getUrl())}
                            >
                                <Typography weight="semibold" size="sm" color={COLORS.BLACK}>
                                    Block Users
                                </Typography>
                            </Button>
                        </Flex>
                    </IfCanAccess>

                    <IfCanAccess action={ADMIN_OPERATION.profileView}>
                        <Flex alignItems="center" gap={10} className={classnames(Styles.profileOption)}>
                            <Button
                                fillType="text"
                                onClick={() => handleOptionClick(ROUTES.adminConsoleSendWhatsAppMessage.getUrl())}
                            >
                                <Typography weight="semibold" size="sm" color={COLORS.BLACK}>
                                    Send Whatsapp Message
                                </Typography>
                            </Button>
                        </Flex>
                    </IfCanAccess>
                    {Boolean(fromFlutterApp) ? (
                        <></>
                    ) : (
                        <Flex
                            alignItems="center"
                            gap={10}
                            className={classnames(Styles.profileOption)}
                            onClick={handleAdminConsoleLogout}
                        >
                            <Button fillType="text" id={IDs.profileDropDown.profileLogout}>
                                <Typography weight="semibold" size="sm" color={COLORS.RED}>
                                    Logout
                                </Typography>
                            </Button>
                        </Flex>
                    )}
                </Flex>
            </Flex>
        );
    };

    return (
        <div className={Styles.main}>
            {!midScreen ? (
                <Dropdown
                    isOpen={isDropDownOpen}
                    DropdownToggleBtn={<AvatarImage imageUrl={companyLogo} alt={name || "Vahak User"} size="sm" />}
                    handleToggle={toggleProfilePopup}
                    containerClassName={""}
                    direction="left"
                    dropdownClassName={Styles.profileDropdown}
                    dropdownToggleClassName={Styles.profileDropdownDropdownToggle}
                    id={IDs.header.profileAvatar}
                >
                    {renderOptions()}
                </Dropdown>
            ) : (
                <>
                    <AvatarImage size="sm" imageUrl={companyLogo} onClick={toggleUserProfileSheetPopup} />
                </>
            )}

            {/* Bottom sheet */}

            {isUserProfileSheetVisible ? (
                <BottomSheet
                    isOpen={isUserProfileSheetVisible}
                    onToggle={toggleUserProfileSheetPopup}
                    showCloseIcon={false}
                    showHeader={false}
                >
                    {renderOptions()}
                </BottomSheet>
            ) : (
                <></>
            )}
        </div>
    );
};

export default ProfileItemPopupMenu;
