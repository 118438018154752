import { generateSelectOption } from "../methods/uiUtil";

export enum VASServices {
    MEMBERSHIP = 1,
    GPS = 2,
    CARGO_INSURANCE = 3
}

export const VASServicesName: {
    [key in VASServices]: string;
} = {
    [VASServices.MEMBERSHIP]: "Membership",
    [VASServices.GPS]: "GPS",
    [VASServices.CARGO_INSURANCE]: "Cargo Insurance"
};

export enum MembershipPlans {
    SHIPPER = 1,
    TRUCKER = 2,
    TRANSPORTER = 3
}

export const MembershipPlansName = {
    [MembershipPlans.SHIPPER]: "Shipper",
    [MembershipPlans.TRUCKER]: "Trucker",
    [MembershipPlans.TRANSPORTER]: "Transporter"
};

export function getMembershipTypeFromName(planName: string): MembershipPlans | undefined {
    for (const key in MembershipPlansName) {
        const typedKey = key as unknown as MembershipPlans;
        if (MembershipPlansName[typedKey] === planName) {
            return typedKey;
        }
    }
    return undefined;
}

export const VASServicesOptions = [VASServices.MEMBERSHIP, VASServices.GPS, VASServices.CARGO_INSURANCE].map((value) =>
    generateSelectOption(VASServicesName[value], value)
);

export const MembershipTypeOptions = [
    MembershipPlans.SHIPPER,
    MembershipPlans.TRUCKER,
    MembershipPlans.TRANSPORTER
].map((value) => generateSelectOption(MembershipPlansName[value], value));

export enum GpsPlanType {
    "GPS Plan" = 2,
    "SIM Change" = 3,
    "RTO Passing" = 4,
    "Mining" = 5
}
