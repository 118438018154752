import React, { ReactChildren, useContext } from "react";
import { useRouter } from "next/router";
// import FadeIn from "react-lazyload-fadein";
import classnames from "classnames";

// Logos
import PlaystoreBadge from "@vahak/core/dist/icons/PlaystoreBadge.svg";

// Components
import MostSearchedLinks from "./MostSearchedLinks/MostSearchedLinks";
import SocialLinks from "./SocialLinks/SocialLinks";
import AppLink from "components/utils/app-link/AppLink";

// Utils & helper functions
import A from "@vahak/core/dist/components/app-anchor/A";
import { AppContext } from "@vahak/core/dist/app-context";
import { FOOTER_ITEM_ATTRIBUTE, FooterItemsConstant } from "@vahak/core/dist/constants/misc";
import { GA4EventNames, GA4AppClickSource, GA4ContactUsClickSource } from "@vahak/core/dist/constants/event-names";
import { FooterItemsGaEvents } from "@vahak/core/dist/constants/eventsMapObj";

import VahakLogoWhite from "@vahak/core/dist/icons/VahakLogoWhite_v03.svg";

// Style
import footerStyles from "./Footer.module.scss";

import { getTransporterFigure, getTransporterFigureTypes } from "@vahak/core/dist/methods/getTransporterFigure";
import { ROUTES } from "@vahak/core/dist/constants/routeStrings";
import { ga4sendEvent, moEngageUserEventTrack } from "@vahak/core/dist/_services/user-analytics.service";
import { CompanyServiceType } from "@vahak/core/dist/_services/auth.service";

const HighlightedLink = ({ path, children }: { path: string; children: ReactChildren | string }) => {
    return (
        <>
            &nbsp;
            <AppLink href={path} className={footerStyles.link__highlighted}>
                {children}
            </AppLink>
            &nbsp;
        </>
    );
};
const Footer = () => {
    const { isMobile, userId, name, serviceType, companyId } = useContext(AppContext);
    const router = useRouter();

    const onClickSendGaEvents = (e: React.MouseEvent<HTMLLIElement | HTMLDivElement, MouseEvent>) => {
        const attribute = e.currentTarget.getAttribute(FOOTER_ITEM_ATTRIBUTE);

        if (attribute) {
            let val = FooterItemsGaEvents[attribute];
            if (val !== undefined) {
                ga4sendEvent("website_bottom_navigation_web", { value: val });
            }
            if (attribute === FooterItemsConstant.DOWNLOAD_OUR_APP) {
                ga4sendEvent(GA4EventNames.Download_app_clicked_web, {
                    company_id: companyId,
                    user_name: name,
                    service_type: serviceType,
                    source_screen: GA4AppClickSource.homepage_bottom_screen
                });
            } else if (attribute === FooterItemsConstant.CONTACT_US) {
                ga4sendEvent(GA4EventNames.Contact_us_clicked_web, {
                    company_id: companyId,
                    user_name: name,
                    service_type: serviceType,
                    section: GA4ContactUsClickSource.homepage_bottom_section
                });
            }
        }
    };

    return (
        <>
            <footer className={classnames(footerStyles.footer)}>
                <div className="container">
                    <div className={classnames("row", footerStyles.row, footerStyles.topLinks)}>
                        <div className={classnames(footerStyles.col, "col-12", "col-lg-4")}>
                            <div className={classnames(footerStyles.logoFooter)}>
                                <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        if (companyId) {
                                            if (router?.pathname?.includes("/dashboard")) return;
                                            router.push(ROUTES.dashboard.getUrl());
                                        } else {
                                            if (router?.pathname?.includes("/marketplace")) return;
                                            if (serviceType == CompanyServiceType.SHIPPER) {
                                                router.push(ROUTES.marketplace.getUrl("lorry"));
                                            } else {
                                                router.push(ROUTES.marketplace.getUrl("load"));
                                            }
                                        }
                                    }}
                                    title="Vahak - Book Trucks, Trailers, Containers from Thousands of Trusted Transport businesses and Lorry Owners from All India"
                                >
                                    <VahakLogoWhite width="140" />
                                </div>
                            </div>

                            <p>
                                Book trucks, trailers, containers from thousands of trusted transport businesses and
                                lorry owners all across India.
                            </p>
                        </div>
                        {isMobile && (
                            <div className={classnames(footerStyles.col, "col-12")}>
                                <h4 className={classnames(footerStyles.footerHead)}>Connect with us on</h4>
                                <SocialLinks />
                            </div>
                        )}
                        <div className={classnames(footerStyles.col, "col-6", "col-md-4", "col-lg-3")}>
                            <h4 className={classnames(footerStyles.footerHead)}>Company</h4>
                            <ul className={classnames(footerStyles.footerList)}>
                                <li
                                    {...{ [FOOTER_ITEM_ATTRIBUTE]: FooterItemsConstant.ABOUT_US }}
                                    onClick={onClickSendGaEvents}
                                >
                                    <AppLink href="/about-us" title="Feel free to contact Vahak team for any queries">
                                        About us
                                    </AppLink>
                                </li>
                                <li
                                    {...{ [FOOTER_ITEM_ATTRIBUTE]: FooterItemsConstant.CONTACT_US }}
                                    onClick={() => {
                                        onClickSendGaEvents;
                                        moEngageUserEventTrack("contact_us_clicked_web", {
                                            user_id: userId,
                                            user_name: name,
                                            service_type: serviceType,
                                            section: "Homepage bottom section"
                                        });
                                    }}
                                >
                                    <AppLink href="/contact-us" title="Feel free to contact Vahak team for any queries">
                                        Contact Us
                                    </AppLink>
                                </li>
                                <li
                                    {...{ [FOOTER_ITEM_ATTRIBUTE]: FooterItemsConstant.CAREERS }}
                                    onClick={onClickSendGaEvents}
                                >
                                    <AppLink href="/careers" title="Click to know more about open positions in Vahak">
                                        Careers <span className={classnames(footerStyles.hiring)}>We’re hiring</span>
                                    </AppLink>
                                </li>
                                <li
                                    {...{ [FOOTER_ITEM_ATTRIBUTE]: FooterItemsConstant.GLOSSARY }}
                                    onClick={onClickSendGaEvents}
                                >
                                    <AppLink href={ROUTES.glossary.getUrl()} title="Click to know more about VAHAK">
                                        Glossary
                                    </AppLink>
                                </li>
                                <li
                                    {...{ [FOOTER_ITEM_ATTRIBUTE]: FooterItemsConstant.BLOGS }}
                                    onClick={onClickSendGaEvents}
                                >
                                    <AppLink href={"/blogs"} title="Click to know more about VAHAK">
                                        Blogs
                                    </AppLink>
                                </li>
                            </ul>

                            <div className={classnames("d-block", "d-md-none", "mt-5")}>
                                <h4 className={classnames(footerStyles.footerHead)}>Transport Nagar</h4>
                                <ul className={classnames(footerStyles.footerList)}>
                                    <li
                                        {...{ [FOOTER_ITEM_ATTRIBUTE]: FooterItemsConstant.FAQ }}
                                        onClick={onClickSendGaEvents}
                                    >
                                        <AppLink
                                            href={`/transport-nagar/sanjay-gandhi`}
                                            title="Sanjay gandhi transport nagar"
                                        >
                                            Sanjay Gandhi
                                        </AppLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className={classnames(footerStyles.col, "col-6", "col-md-4", "col-lg-2")}>
                            <h4 className={classnames(footerStyles.footerHead)}>Misc</h4>
                            <ul className={classnames(footerStyles.footerList)}>
                                <li
                                    {...{ [FOOTER_ITEM_ATTRIBUTE]: FooterItemsConstant.FAQ }}
                                    onClick={onClickSendGaEvents}
                                >
                                    <AppLink
                                        href={`/frequently-asked-questions`}
                                        title="Click to know more about FAQ’s"
                                    >
                                        Frequently Asked Questions
                                    </AppLink>
                                </li>
                                <li
                                    {...{ [FOOTER_ITEM_ATTRIBUTE]: FooterItemsConstant.TERMS_AND_CONDITION }}
                                    onClick={onClickSendGaEvents}
                                >
                                    <AppLink
                                        href={`/terms-and-conditions`}
                                        title="Click to read our Terms and Conditions"
                                        locale=""
                                    >
                                        Terms and Conditions
                                    </AppLink>
                                </li>
                                <li
                                    {...{ [FOOTER_ITEM_ATTRIBUTE]: FooterItemsConstant.PAYMENT_GUIDELINES }}
                                    onClick={onClickSendGaEvents}
                                >
                                    <AppLink
                                        href={`/payments-terms-and-conditions`}
                                        title="Click to know more about Payment Guidelines"
                                        locale=""
                                    >
                                        Payment Guidelines and T&C
                                    </AppLink>
                                </li>
                                <li
                                    {...{ [FOOTER_ITEM_ATTRIBUTE]: FooterItemsConstant.CASHBACK_TERMS_AND_CONDITIONS }}
                                    onClick={onClickSendGaEvents}
                                >
                                    <AppLink
                                        href="/cashback-terms-and-condition"
                                        title="Click to know more about Cashback Guidelines"
                                        locale=""
                                    >
                                        Cashback T&C
                                    </AppLink>
                                </li>
                                <li
                                    {...{ [FOOTER_ITEM_ATTRIBUTE]: FooterItemsConstant.MEDIBUDDY_TERMS_AND_CONDITIONS }}
                                    onClick={onClickSendGaEvents}
                                >
                                    <AppLink
                                        href="/medibuddy-terms-and-conditions"
                                        title="Click to know more about Medibuddy Terms and Conditions"
                                        locale=""
                                    >
                                        Medibuddy T&C
                                    </AppLink>
                                </li>
                                <li
                                    {...{ [FOOTER_ITEM_ATTRIBUTE]: FooterItemsConstant.TAP_N_GO_TERMS_AND_CONDITIONS }}
                                    onClick={onClickSendGaEvents}
                                >
                                    <AppLink
                                        href="/tapngo-terms-and-conditions"
                                        title="Click to know more about Tap N Go Terms and Conditions"
                                        locale=""
                                    >
                                        Assured Loads T&C
                                    </AppLink>
                                </li>
                                {/* <li
                                    {...{
                                        [FOOTER_ITEM_ATTRIBUTE]: FooterItemsConstant.BUSINESS_LOANS_TERMS_AND_CONDITIONS
                                    }}
                                    onClick={onClickSendGaEvents}
                                >
                                    <AppLink
                                        href="/business-loans-terms-and-conditions"
                                        title="Click to know more about  Business Loans Terms and Conditions"
                                        locale=""
                                    >
                                        Business Loans T&C
                                    </AppLink>
                                </li> */}
                                {/* <li
                                    {...{
                                        [FOOTER_ITEM_ATTRIBUTE]:
                                            FooterItemsConstant.CARGO_INSURANCE_TERMS_AND_CONDITIONS
                                    }}
                                    onClick={onClickSendGaEvents}
                                >
                                    <AppLink
                                        href={`/${FooterItemsConstant.CARGO_INSURANCE_TERMS_AND_CONDITIONS}`}
                                        title="Cargo Insurance Terms and Conditions"
                                        locale=""
                                    >
                                        Cargo Insurance T&C
                                    </AppLink>
                                </li> */}
                                <li
                                    {...{
                                        [FOOTER_ITEM_ATTRIBUTE]: FooterItemsConstant.GPS_TERMS_AND_CONDITIONS
                                    }}
                                    onClick={onClickSendGaEvents}
                                >
                                    <AppLink
                                        href={`/${FooterItemsConstant.GPS_TERMS_AND_CONDITIONS}`}
                                        title="GPS Terms and Conditions"
                                        locale=""
                                    >
                                        GPS T&C
                                    </AppLink>
                                </li>
                                <li
                                    {...{ [FOOTER_ITEM_ATTRIBUTE]: FooterItemsConstant.PRIVACY_POLICY }}
                                    onClick={onClickSendGaEvents}
                                >
                                    <AppLink
                                        href={`/privacy-policy`}
                                        title="Click to read our Privacy Policy"
                                        locale=""
                                    >
                                        Privacy Policy
                                    </AppLink>
                                </li>
                            </ul>
                        </div>
                        <div
                            className={classnames(
                                footerStyles.col,
                                "col-6",
                                "col-md-4",
                                "col-lg-2",
                                "d-none",
                                "d-md-block"
                            )}
                        >
                            <h4 className={classnames(footerStyles.footerHead)}>Transport Nagar</h4>
                            <ul className={classnames(footerStyles.footerList)}>
                                <li
                                    {...{ [FOOTER_ITEM_ATTRIBUTE]: FooterItemsConstant.FAQ }}
                                    onClick={onClickSendGaEvents}
                                >
                                    <AppLink
                                        href={`/transport-nagar/sanjay-gandhi`}
                                        title="Sanjay gandhi transport nagar"
                                    >
                                        Sanjay Gandhi
                                    </AppLink>
                                </li>
                            </ul>
                        </div>
                        {/* Temporarily hidden */}
                        <div className={classnames(footerStyles.col, "d-none", "col-6", "col-md-4", "col-lg-2")}>
                            <h4 className={classnames(footerStyles.footerHead)}>Products</h4>
                            <ul className={classnames(footerStyles.footerList)}>
                                <li>
                                    <AppLink
                                        href={`${ROUTES.marketplace.getUrl("load")}`}
                                        title="Click to know more about live marketplace"
                                    >
                                        Live Marketplace
                                    </AppLink>
                                </li>
                                <li>
                                    <AppLink
                                        href={`/transporters`}
                                        title="Click to know more about Transport Directory"
                                    >
                                        Transport Directory
                                    </AppLink>
                                </li>
                            </ul>
                        </div>
                        {/* Temporarily hidden */}
                        {/* <div className={classnames(footerStyles.col, "d-none", "col-6", "col-md-4", "col-lg-2")}>
                            <h4 className={classnames(footerStyles.footerHead)}>Resources</h4>
                            <ul className={classnames(footerStyles.footerList)}>
                                <li>
                                    <Link href={`/frequently-asked-questions`}>
                                        <a title="Click to know more about FAQ’s">FAQs</a>
                                    </Link>
                                </li>
                                <li>
                                    <Link href="/blogs">
                                        <a title="Click to know more about Blogs">Blogs</a>
                                    </Link>
                                </li>
                                <li>
                                    <Link href="#">
                                        <a title="Click to know more about Press">Press</a>
                                    </Link>
                                </li>
                            </ul>
                        </div> */}
                        {/* Temporarily hidden */}
                        {/* <div className={classnames(footerStyles.col, "d-none", "col-6", "col-md-4", "col-lg-2")}>
                            <h4 className={classnames(footerStyles.footerHead)}>Legal</h4>
                            <ul className={classnames(footerStyles.footerList)}>
                                <li>
                                    <Link href={`/privacy-policy`}>
                                        <a title="Click to know more about privacy policy">Privacy Policy</a>
                                    </Link>
                                </li>
                                <li>
                                    <Link href={`/terms-and-conditions`}>
                                        <a title="Click to know more about Terms & Conditions">Terms & Conditions</a>
                                    </Link>
                                </li>
                            </ul>
                        </div> */}
                    </div>
                    <div className={classnames("row", footerStyles.row, footerStyles.getInTouch)}>
                        {!isMobile && (
                            <div className={classnames(footerStyles.col, "col-12", "col-lg-4")}>
                                <h4 className={classnames(footerStyles.footerHead)}>Connect with us on</h4>
                                <SocialLinks />
                            </div>
                        )}
                        <div className={classnames(footerStyles.col, "col-lg-3")}>
                            <h4 className={classnames(footerStyles.footerHead)}>Download our app on</h4>
                            <div
                                {...{ [FOOTER_ITEM_ATTRIBUTE]: FooterItemsConstant.DOWNLOAD_OUR_APP }}
                                onClick={() => {
                                    onClickSendGaEvents;
                                    moEngageUserEventTrack("download_app_clicked_web", {
                                        user_id: userId,
                                        user_name: name,
                                        service_type: serviceType,
                                        source_section: "Footer section"
                                    });
                                }}
                            >
                                <A
                                    openInNewTab
                                    title="Book Trucks for all India, Download Vahak"
                                    href="https://6r7cz.app.link/googleplay"
                                >
                                    <PlaystoreBadge
                                        className={classnames(footerStyles.footerPlaystoreIcon, "img-fluid")}
                                        width="165"
                                        height="50"
                                        title="Book Trucks for all India, Download Vahak"
                                    />
                                </A>
                            </div>
                        </div>
                    </div>
                    {/* Divider */}
                    <div className={classnames(footerStyles.divider)} />
                    <div className={classnames("row", footerStyles.row, footerStyles.mostSearchedLinkContainer)}>
                        <div className={classnames(footerStyles.col, "col")}>
                            <MostSearchedLinks />
                        </div>
                    </div>
                    {/* Divider */}
                    <div className={classnames(footerStyles.divider)} />
                    <div className={classnames("row", footerStyles.row)}>
                        <div className={classnames("col", footerStyles.col)}>
                            <div className={classnames(footerStyles.trustContent)}>
                                <p>
                                    Join India’s largest & most trusted transport community VAHAK. You can post your
                                    enquiry, attach lorries,
                                    <HighlightedLink path={"/online-load-booking"}>book trucks online</HighlightedLink>
                                    and bid on truck loads. Vahak is an online transport marketplace & networking
                                    community of truck owners, transporters and consignors. Get in touch with India’s
                                    top performing transporters & lorry owners with Vahak. Vahak mobile app is 100% free
                                    to use and provides a seamless interface to book lorries and loads online. There are
                                    no extra fees involved! Enjoy a commission-free experience. Book trucks, trailers,
                                    containers, Hyva, LCVs as per your shipping requirement for intercity and intracity
                                    loads. Vahak network is spread across major transport hubs including logistic hubs
                                    and cities like New Delhi, Noida, Gurugram, Ghaziabad, Faridabad, Chandigrh, Mumbai,
                                    Navi Mumbai, Thane, Ahmedabad, Vadodara, Pune, Nashik, Kolkata, Chennai,
                                    Visakhapatnam, Bangalore, Hyderabad, Mysore, Raipur, Bhilai, Bhopal, Kanpur,
                                    Lucknow, Bengaluru, Kochi, Vapi, Surat, Nagpur, Patna, Indore. Vahak helps in
                                    connecting and bringing business for Shippers, Road Transport Contractors, Lorry
                                    Owners, Freight Booking & Commission Agents, Transportation Service Providers,
                                    Logistic Companies, Consignors & Factory Owners, Small & medium business owners, and
                                    Transport Agents. Create your own successful transport business brand with Vahak!
                                </p>
                                <p>
                                    Vahak is India’s first
                                    <HighlightedLink path={"/transporters"}>online transport directory</HighlightedLink>
                                    app for road transport businesses and individual commercial vehicle (Trucks,
                                    Trailers, Containers, Hyva, LCVs) owners for online truck and load booking,
                                    transport business branding and
                                    <HighlightedLink path={"/grow-network"}>
                                        transport business network expansion
                                    </HighlightedLink>
                                    . Lorry owners can find intercity and intracity loads from all over India and
                                    connect with other businesses to find trusted transporters and best deals in the
                                    Indian Logistics services market. Get started for free by downloading the Vahak app
                                    now. Register your transport business now. Vahak is trusted by more than
                                    {getTransporterFigure(getTransporterFigureTypes.NUMERIC)} Transporters and Lorry
                                    owners in over 10,000+ locations for daily transport requirements. Join VAHAK today!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <footer className={classnames(footerStyles.footer, footerStyles.footerBar)}>
                <div className="container">
                    {/* Divider */}
                    <div className={classnames(footerStyles.divider)} />
                    <div className={classnames(footerStyles.row, "row")}>
                        <div className={classnames(footerStyles.col, "col-12", "order-1", "col-sm-5")}>
                            <div className={classnames(footerStyles.copyRights)}>
                                <p>
                                    © {new Date().getFullYear()} Epictus Solutions India Private Limited. All Rights
                                    Reserved
                                </p>
                            </div>
                        </div>
                        <div className={classnames(footerStyles.col, "col-12", "order-3", "col-sm-2", "order-sm-2")}>
                            <div className={classnames(footerStyles.madeInIndia)}>
                                <span>
                                    Made with{" "}
                                    <span role="img" aria-label="love">
                                        ❤️
                                    </span>{" "}
                                    in India
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;
