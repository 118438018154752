import React, { ReactNode, useContext } from "react";
import { DefaultLayoutProp } from "../default-layout/DefaultLayout";
import { AppContext } from "@vahak/core/dist/app-context";
import useMediaQuery from "@vahak/core-ui/dist/hooks/useMediaQuery";
import { PlainLayout } from "../plain-layout/PlainLayout";
import SideNavLayoutWrapper from "../SideNavLayoutWrapper/SideNavLayoutWrapper";
import DefaultLayoutWrapper from "../DefaultLayoutWrapper/DefaultLayoutWrapper";

interface IMembershipLayoutWrapper extends DefaultLayoutProp {
    children: ReactNode;
    pageRoute?: string;
}

export const MembershipLayoutWrapper = ({ ...props }: IMembershipLayoutWrapper) => {
    const isMobileScreen = useMediaQuery({ queryType: "mobile" });
    const { isPhoneVerified, companyId } = useContext(AppContext);
    const { content: Content, children, navContent, pageRoute } = props;

    if (isMobileScreen) {
        return <PlainLayout page={children} />;
    }

    if (isPhoneVerified && companyId) {
        return <SideNavLayoutWrapper>{children}</SideNavLayoutWrapper>;
    } else {
        return <DefaultLayoutWrapper navContent={navContent}>{children}</DefaultLayoutWrapper>;
    }
};
