import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import { useCookies } from "react-cookie";

//Style
import Styles from "./LoginFormFieldWrapper.module.scss";

//Assets
import VahakIcon from "@vahak/core/dist/standard-icons/vahak-logo.svg";

//Components
import AnimateContainer, { AnimateContainerProps } from "../animate-container/AnimateContainer";
import PhoneNumberField from "../login-form-fields/phone-number-field/PhoneNumberField";
import Flex from "@vahak/core-ui/dist/layout/Flex";

//Context
import { AppContext } from "@vahak/core/dist/app-context";
import { LOGIN_FORM_STEPS, LoginFormFieldsTypes, initialLoginFormValues, loginFormFieldNames } from "../common/contant";
import Typography from "@vahak/core-ui/dist/components/Typography";
import { getLoginValidationSchema } from "../validation/validationSchema";
import OtpField from "../login-form-fields/otp-field/OtpField";
import { useAuth } from "@vahak/core/dist/components/auth/useAuth";
import { LoginScope } from "@vahak/core/dist/_services/sms.service";
import CompanyRegistrationField from "../login-form-fields/company-registration-field/CompanyRegistrationField";
import {
    COOKIES,
    DefaultCookieOptions,
    GA4EventNames,
    GA4LoginOrRegistrationModalEventAttributes,
    ROUTES,
    VWOEvents
} from "@vahak/core/dist/constants";
import { useRouter } from "next/router";
import SuccessScreen from "../success-screen/SuccessScreen";
import {
    CompanyServiceType,
    moEngageUserAttributeTrack,
    moEngageUserPhoneNum,
    useEventTrackerService,
    WebsiteUserType
} from "@vahak/core/dist/_services";
import { GenericObject } from "@vahak/core-ui/dist/types";
import { moEngageUserLogIn } from "@vahak/core/dist/_services";
import { BiddingFormContext } from "../../../BiddingFormContextProvider";
import useMediaQuery from "@vahak/core-ui/dist/hooks/useMediaQuery";

interface LoginFormFieldWrapperProps {
    setCurrentStep: (step: LOGIN_FORM_STEPS) => void;
}

const LoginFormFieldWrapper = ({ setCurrentStep }: LoginFormFieldWrapperProps) => {
    const {
        phoneNum,
        referralCode,
        serviceType,
        setPhoneNum,
        setReferralCode,
        companyId,
        isPhoneVerified,
        globalData,
        setShouldPreventLayoutSwitch,
        shouldPreventLayoutSwitch
    } = useContext(AppContext);

    const [cookies, setCookie, removeCookie] = useCookies<string>([]);

    const { bidFormConfig } = useContext(BiddingFormContext);

    const [animation, setAnimation] = useState<AnimateContainerProps["animation"]>("fadeIn");
    const [activeStep, setActiveStep] = useState<LOGIN_FORM_STEPS>(
        isPhoneVerified && !Boolean(companyId) ? LOGIN_FORM_STEPS.COMPANY_NAME : LOGIN_FORM_STEPS.PHONE_NUMBER
    );

    const router = useRouter();

    const { sendGAandMoEngageEvent } = useEventTrackerService();

    const handleEventTracking = (name: string, data?: GenericObject) => {
        sendGAandMoEngageEvent({
            name,
            data: {
                source_screen: GA4LoginOrRegistrationModalEventAttributes.phone_number_registration,
                phone_number: phoneNum,
                ...data
            }
        });
    };

    useEffect(() => {
        setCurrentStep(activeStep);
    }, [activeStep]);

    const formik = useFormik({
        initialValues: {
            ...initialLoginFormValues,
            phoneNumber: phoneNum,
            referralCode: referralCode,
            serviceType: serviceType ?? CompanyServiceType.TRANSPORT_CONTRACTOR,
            showCurrentLocationField: true,
            ...(Boolean(globalData?.userPlaceInfo?.place_id) && {
                currentPlaceID: globalData?.userPlaceInfo?.place_id,
                showCurrentLocationField: true
            })
        } as LoginFormFieldsTypes,
        validationSchema: getLoginValidationSchema(activeStep),
        validateOnChange: true,
        validateOnMount: true,
        validateOnBlur: true,
        enableReinitialize: true,
        onSubmit: async (values: LoginFormFieldsTypes) => {
            if (activeStep === LOGIN_FORM_STEPS.PHONE_NUMBER) {
                setPhoneNum?.(values.phoneNumber);
                onSubmitPhoneNum();

                /**
                 * Events
                 */
                handleEventTracking(GA4EventNames.LOGIN_OR_SIGNUP_MODAL["get_otp_clicked"]);
                moEngageUserAttributeTrack("moe_wa_subscription", String(values?.whatsappUpdatesEnabled));

                setAnimation("fadeOut");
                setTimeout(() => {
                    setActiveStep(LOGIN_FORM_STEPS.OTP);
                    setAnimation("fadeIn");
                }, 500);
            } else if (activeStep === LOGIN_FORM_STEPS.OTP) {
                setShouldPreventLayoutSwitch?.(true);
                const status = await onSubmitOtp();
                /**
                 * Events
                 */
                handleEventTracking(GA4EventNames.LOGIN_OR_SIGNUP_MODAL["otp_submit"]);

                if (!status) {
                    formik.setFieldError(loginFormFieldNames.otp, "Please enter the right OTP");
                    return;
                }

                if (status && status.companyName) {
                    /**
                     * Events - Already registered company
                     */
                    handleEventTracking(GA4EventNames.LOGIN_OR_SIGNUP_MODAL["user_login"], {
                        login_via: "manual",
                        company_id: status.companyId,
                        user_id: status.userId
                    });

                    moEngageUserLogIn(Number(status.userId));
                    moEngageUserAttributeTrack("Name", status.companyName);
                    moEngageUserAttributeTrack("Phone", formik?.values?.phoneNumber || phoneNum);
                    moEngageUserAttributeTrack("company_id", status.companyId);
                    moEngageUserAttributeTrack("user_id", status.userId);
                    moEngageUserAttributeTrack("user_type", WebsiteUserType.CLIENT);
                    window?.VWO?.event(VWOEvents.login_success);

                    if (router.route === "/") {
                        router.push(ROUTES.dashboard.getUrl());
                    } else if (router.asPath.includes("marketplace")) {
                        /**
                         * This condition is checked to change the marketplace active tab
                         * Since its server side page, getServerSideProps will not be updated and causes issue in rendering
                         * To over come that and to navigate to right service based on service type this condition is added
                         * This condition can be optimized, but didn't touch because it works :)
                         */
                        const routeArray = router.asPath.split("#")[0].split("?")[0].split("/");
                        if (status.serviceType === CompanyServiceType.FLEET_OWNER) {
                            const indexToReplace = routeArray.indexOf("lorry");
                            if (indexToReplace !== -1) {
                                routeArray[indexToReplace] = "load";
                                router.replace(
                                    {
                                        pathname: routeArray.join("/"),
                                        hash: undefined
                                    },
                                    undefined,
                                    { shallow: false }
                                );
                            } else {
                                router.replace(
                                    {
                                        pathname: routeArray.join("/"),
                                        hash: undefined
                                    },
                                    undefined,
                                    { shallow: true }
                                );
                            }
                        } else if (status.serviceType === CompanyServiceType.SHIPPER) {
                            const indexToReplace = routeArray.indexOf("load");
                            if (indexToReplace !== -1) {
                                routeArray[indexToReplace] = "lorry";
                                router.replace(
                                    {
                                        pathname: routeArray.join("/"),
                                        hash: undefined
                                    },
                                    undefined,
                                    { shallow: false }
                                );
                            } else {
                                router.replace(
                                    {
                                        pathname: routeArray.join("/"),
                                        hash: undefined
                                    },
                                    undefined,
                                    { shallow: true }
                                );
                            }
                        } else {
                            router.replace(
                                {
                                    pathname: routeArray.join("/"),
                                    hash: undefined
                                },
                                undefined,
                                { shallow: true }
                            );
                        }
                    } else {
                        router.replace(
                            {
                                ...router,
                                hash: undefined
                            },
                            undefined,
                            { shallow: true }
                        );
                    }
                    setShouldPreventLayoutSwitch?.(false);
                    return;
                }

                /**
                 * Events - Already registered company
                 */
                handleEventTracking(GA4EventNames.LOGIN_OR_SIGNUP_MODAL["user_signup"], {
                    sign_up_via: "manual",
                    company_id: status.companyId,
                    user_id: status.userId
                });
                moEngageUserLogIn(Number(status.userId));
                moEngageUserAttributeTrack("user_id", status.userId);
                moEngageUserAttributeTrack("setPhoneNumber", phoneNum);
                moEngageUserAttributeTrack("phone_number", phoneNum);
                moEngageUserAttributeTrack("user_signedup", status?.createdAt);
                moEngageUserAttributeTrack("user_type", WebsiteUserType.CLIENT);
                phoneNum && moEngageUserPhoneNum(phoneNum);

                /**
                 * Go to next step
                 */
                setAnimation("fadeOut");
                setTimeout(() => {
                    setActiveStep(LOGIN_FORM_STEPS.COMPANY_NAME);
                    setAnimation("fadeIn");
                }, 500);
            } else if (activeStep === LOGIN_FORM_STEPS.COMPANY_NAME) {
                setShouldPreventLayoutSwitch?.(true);
                const result = await onSubmitCompanyDetails();

                handleEventTracking(GA4EventNames.COMPANY_REGISTRATION["company_details_submitted"], {
                    source_screen: "company_registration",
                    services: values.serviceType,
                    company_name: values.companyName,
                    referral_code: values.referralCode,
                    company_id: result?.companyId,
                    user_id: result?.userId
                });
                handleEventTracking(GA4EventNames.COMPANY_REGISTRATION["user_registered"], {
                    source_screen: "company_registration",
                    services: values.serviceType,
                    company_name: values.companyName,
                    referral_code: values.referralCode,
                    company_id: result?.companyId,
                    user_id: result?.userId
                });

                if (result?.status) {
                    setCookie(COOKIES.newUser, JSON.stringify("true"), {
                        ...DefaultCookieOptions,
                        expires: new Date(Date.now() + 5 * 60 * 1000),
                        maxAge: 60
                    });

                    /**
                     * Events
                     */
                    moEngageUserAttributeTrack("service", values.serviceType);
                    moEngageUserAttributeTrack("setFirstName", values.companyName);
                    moEngageUserAttributeTrack("company_name", values.companyName);
                    moEngageUserAttributeTrack("user_registered", result?.createdAt);
                    window?.VWO?.event(VWOEvents.sign_up_success);

                    /**
                     * Go to next step
                     */
                    if (!(bidFormConfig.load.open || bidFormConfig.lorry.open)) {
                        setAnimation("fadeOut");
                        setTimeout(() => {
                            setActiveStep(LOGIN_FORM_STEPS.SUCCESS_SCREEN);
                            setAnimation("fadeIn");
                        }, 500);
                    } else {
                        setShouldPreventLayoutSwitch?.(false);
                    }
                    return;
                } else {
                    setShouldPreventLayoutSwitch?.(false);
                    delete router.query["referral_code"];
                    router.replace(
                        {
                            ...router,
                            hash: undefined
                        },
                        undefined,
                        { shallow: true }
                    );
                    setReferralCode?.(undefined);
                    return;
                }
            }
        }
    });

    const { onSubmitPhoneNum, onClickResendOtp, onSubmitOtp, onSubmitCompanyDetails } = useAuth({
        phoneNum: formik?.values?.phoneNumber || phoneNum,
        otp: Number(formik?.values.otp),
        name: formik?.values.companyName,
        serviceType: formik?.values.serviceType,
        placeId: formik?.values.currentPlaceID,
        whatsappUpdatesEnabled: formik?.values.whatsappUpdatesEnabled,
        isInitiatedFrom: "Login Page",
        referralCode: formik?.values.referralCode,
        otpScope: LoginScope.LOGIN
    });

    const onClickEditNumber = () => {
        formik.setFieldValue(loginFormFieldNames.otp, "");
        setActiveStep(LOGIN_FORM_STEPS.PHONE_NUMBER);

        /**
         * Events
         */
        handleEventTracking(GA4EventNames.LOGIN_OR_SIGNUP_MODAL["otp_screen_edit_phone_number_clicked"]);
    };

    const isMobileScreen = useMediaQuery({ queryType: "mobile" });

    const renderVahakLogo = () => {
        return (
            <Flex flexDirection="column" alignItems="center" style={{ marginBottom: "15px" }} gap={16}>
                <VahakIcon />
                <Typography size={isMobileScreen ? "md" : "l"} weight="medium">
                    Welcome to Vahak
                </Typography>
            </Flex>
        );
    };

    const renderSteps = () => {
        switch (activeStep) {
            case LOGIN_FORM_STEPS.PHONE_NUMBER:
                return (
                    <>
                        {renderVahakLogo()}
                        <PhoneNumberField formik={formik} />
                    </>
                );

            case LOGIN_FORM_STEPS.OTP:
                return (
                    <>
                        {renderVahakLogo()}
                        <OtpField
                            formik={formik}
                            onClickResendOtp={onClickResendOtp}
                            onClickEditNumber={onClickEditNumber}
                        />
                    </>
                );

            case LOGIN_FORM_STEPS.COMPANY_NAME:
                return (
                    <>
                        <CompanyRegistrationField formik={formik} />
                    </>
                );

            case LOGIN_FORM_STEPS.SUCCESS_SCREEN:
                return (
                    <>
                        <SuccessScreen />
                    </>
                );

            default:
                break;
        }
    };
    return (
        <div className={Styles.formWrapper}>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    formik.submitForm();
                }}
            >
                <AnimateContainer animation={animation}>{renderSteps()}</AnimateContainer>
            </form>
        </div>
    );
};

export default LoginFormFieldWrapper;
