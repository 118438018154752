import React, { useState } from "react";
import classnames from "classnames";

// Components
import CategoryList from "../CategoryList/CategoryList";

// Style
import mobileFilterStyle from "./MobileFilter.module.scss";

// Icons
import Plus from "@vahak/core/dist/icons/Plus.svg";
import Minus from "@vahak/core/dist/icons/Minus.svg";

// Utils & helper functions
import A from "@vahak/core/dist/components/app-anchor/A";

// Interface
interface Props {
    data: any;
}

const MobileFilter = ({ data }: Props) => {
    const [isOpen, setIsOpen] = useState(true);
    const [activeFilter, setActiveFilter] = useState(data[0].key);
    const handleClick = (val: any) => {
        const { currentFilterIndex } = val;
        if (currentFilterIndex === activeFilter) {
            setIsOpen((state) => !state);
        } else {
            setActiveFilter(currentFilterIndex);
            setIsOpen(true);
        }
    };
    return (
        <div className={classnames(mobileFilterStyle.mobileFilter_layout)}>
            {data.map((menu: any, index: number) => (
                <div key={index}>
                    <A
                        className={classnames(
                            mobileFilterStyle.filterLinks,
                            menu.key === activeFilter && isOpen ? mobileFilterStyle.active : ""
                        )}
                        onClick={() => handleClick({ currentFilterIndex: menu.key, isOpen: isOpen })}
                    >
                        {menu.title} {menu.key === activeFilter && isOpen ? <Minus /> : <Plus />}
                    </A>
                    {
                        <CategoryList
                            selectedList={menu.key}
                            isOpen={menu.key === activeFilter && isOpen ? true : false}
                            isMobile={true}
                            limit={8}
                            title={menu.title}
                        />
                    }
                </div>
            ))}
        </div>
    );
};

export default MobileFilter;
