export type UtilityTypesForMappingInterfaceAsKeyAsWellAsValue<T> = {
    [P in keyof T]: P;
};

export const generateFormFieldNames = <T>(obj: T) => {
    const newObj = {} as UtilityTypesForMappingInterfaceAsKeyAsWellAsValue<T>;
    for (const [key] of Object.entries(obj as any)) {
        // @ts-expect-error
        newObj[key] = key;
    }
    return newObj;
};
