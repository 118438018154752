import React, { useContext } from "react";
// Context
import { AppContext } from "@vahak/core/dist/app-context";

import { CommonLayoutProps } from "../layout-types";
import Footer from "../footer/Footer";

export interface TopNavLayoutProps extends CommonLayoutProps {}

const TopNavLayout = ({ navContent, content: Content, children }: TopNavLayoutProps) => {
    const { isMobile } = useContext(AppContext);
    return (
        <>
            {/* <Suspense fallback={<Loader />}> */}
            {navContent}
            {Content ? <Content /> : children}
            <Footer />
            {/* </Suspense> */}
        </>
    );
};

export default TopNavLayout;
