import React, { ReactNode, useContext } from "react";

// Utils & helper functions
import A from "@vahak/core/dist/components/app-anchor/A";
import classnames from "classnames";
import { AppContext } from "@vahak/core/dist/app-context";
import { ga4sendEvent, moEngageUserEventTrack } from "@vahak/core/dist/_services/user-analytics.service";

// Constants
import { FACEBOOK, INSTAGRAM, LINKEDIN, TWITTER, YOUTUBE } from "@vahak/core/dist/constants/socialMediaLinks";
import { GA4EventNames } from "@vahak/core/dist/constants/event-names";
import { VAHAK_WHATSAPP_LINK_DESKTOP, VAHAK_WHATSAPP_LINK_MOBILE } from "@vahak/core/dist/constants/misc";

// Logos
import Facebook from "@vahak/core/dist/icons/facebook.svg";
import Instagram from "@vahak/core/dist/icons/instagram.svg";
import Linkedin from "@vahak/core/dist/icons/linkedin-logo.svg";
import Whatsapp from "@vahak/core/dist/icons/whatsapp-solid.svg";
import Youtube from "@vahak/core/dist/icons/youtube.svg";
import Twitter from "@vahak/core/dist/icons/twitter.svg";

// Styles
import footerStyles from "../Footer.module.scss";

// Interface
interface Props {}
interface SocialLink {
    id: string;
    title: string;
    openInNewTab: boolean;
    icon: ReactNode;
    link?: string;
    callback?: () => void;
}

const SocialLinks = (props: Props) => {
    const { isMobile, userId, name, serviceType, companyId } = useContext(AppContext);

    const onClickWhatsapp = () => {
        window.open(
            isMobile ? VAHAK_WHATSAPP_LINK_MOBILE : VAHAK_WHATSAPP_LINK_DESKTOP,
            "_blank",
            "noopener noreferrer"
        );
    };
    const handleLinkClick = ({ id, callback }: SocialLink) => {
        if (callback) {
            callback(); // to handle whatsapp link
        }
        ga4sendEvent(GA4EventNames.Connect_with_us_clicked_web, {
            company_id: companyId,
            user_name: name,
            service_type: serviceType,
            channel: id
        });
    };

    const allChannelLinks: SocialLink[] = [
        {
            id: "Facebook",
            title: "Connect with Vahak community on Facebook",
            link: FACEBOOK,
            openInNewTab: true,
            icon: <Facebook width="12" height="25" />
        },
        {
            id: "LinkedIn",
            title: "Connect with Vahak group on LinkedIn",
            link: LINKEDIN,
            openInNewTab: true,
            icon: <Linkedin className={classnames(footerStyles.fillWhite)} width="23.97" height="24.97" />
        },
        {
            id: "Youtube",
            title: "Connect with Vahak channel on Youtube",
            link: YOUTUBE,
            openInNewTab: true,
            icon: <Youtube className={classnames(footerStyles.fillWhite)} width="34.27" height="25.5" />
        },
        {
            id: "WhatsApp",
            title: "Connect with Vahak Transport Market on WhatsApp",
            openInNewTab: true,
            callback: onClickWhatsapp,
            icon: <Whatsapp className={classnames(footerStyles.fillWhite)} width="22" height="22" />
        },
        {
            id: "Instagram",
            title: "Connect with Vahak Transport Market on Instagram",
            link: INSTAGRAM,
            openInNewTab: true,
            icon: <Instagram className={classnames(footerStyles.fillWhite)} width="23.98" height="24" />
        },
        {
            id: "Twitter",
            title: "Connect with Vahak Transport Market on Twitter",
            link: TWITTER,
            openInNewTab: true,
            icon: (
                <Twitter
                    // className={classnames(footerStyles.fillWhite)}
                    width="24"
                    height="24"
                />
            )
        }
    ];

    const onClickHandleMoEngage = (channel: string) => {
        moEngageUserEventTrack("connect_with_us_clicked_web", {
            user_id: userId,
            user_name: name,
            service_type: serviceType,
            channel: channel
        });
    };

    return (
        <div className={classnames(footerStyles.socialIconWrapper)}>
            <div className={classnames(footerStyles.socialIconContainer)}>
                {allChannelLinks.map((item) => {
                    const { icon: Icon, link, openInNewTab, title, id } = item;
                    return (
                        <A
                            key={id}
                            id={id}
                            openInNewTab={openInNewTab}
                            title={title}
                            href={link}
                            onClick={() => handleLinkClick(item)}
                        >
                            {" "}
                            {Icon}
                        </A>
                    );
                })}
            </div>
        </div>
    );
};

export default SocialLinks;
