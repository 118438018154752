import React, { useEffect, useRef, useState } from "react";
import { FormikProps } from "formik";

//Style
import Styles from "./Otp.module.scss";

//Components
import { LoginFormFieldsTypes, loginFormFieldNames } from "../../common/contant";
import COLORS from "@vahak/core-ui/dist/constants/colors";
import Typography from "@vahak/core-ui/dist/components/Typography";

//Assets
import WhatsappIcon from "@vahak/core/dist/icons/WhatsappGreen.svg";
import SmsIcon from "@vahak/core/dist/icons/sms.svg";
import EditIconBlue from "@vahak/core/dist/icons/edit_icon_blue.svg";

import { GA4EventNames, IDs } from "@vahak/core/dist/constants";
import Flex from "@vahak/core-ui/dist/layout/Flex";
import OTPInput from "@vahak/core-ui/dist/components/OTPInput";
import { useEventTrackerService } from "@vahak/core/dist/_services";
import { GenericObject } from "@vahak/core-ui/dist/types";
import { timer } from "@vahak/core/dist/methods/timer";
import { OnClickResendOTPParams } from "@vahak/core/dist/components/auth/useAuth";
import TextCTA from "@vahak/core-ui/dist/components/TextCTA";
import useMediaQuery from "@vahak/core-ui/dist/hooks/useMediaQuery";

interface OtpFieldProps {
    formik: FormikProps<LoginFormFieldsTypes>;
    onClickResendOtp: (p: OnClickResendOTPParams) => void;
    onClickEditNumber?: () => void;
}

const formatToString = (n: number) => {
    return n.toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false
    });
};

const getTimeRemaining = (time: number) => {
    const minutes = formatToString(Math.floor((time / 60) % 60));
    const seconds = formatToString(Math.floor(time % 60));
    return {
        seconds,
        minutes
    };
};

const OtpField = ({ formik, onClickResendOtp, onClickEditNumber }: OtpFieldProps) => {
    const bufferTime = 30;
    const otpLength = 4;
    const [timeRemaining, setTimeRemaining] = useState(bufferTime);
    const [isResendingOtp, setIsResendingOtp] = useState(false);
    const otpInputRef = useRef<HTMLInputElement>(null);

    const onChangeOTP = (str: string) => {
        formik.setFieldValue(loginFormFieldNames.otp, str.trim());
        if (str.trim().length === 4) {
            setTimeout(() => {
                formik.handleSubmit();
            });
        }
    };

    const autoReadOTP = () => {
        const controller = new AbortController();
        setTimeout(() => {
            controller.abort();
        }, 1 * 60 * 1000);

        if ("OTPCredential" in window) {
            try {
                navigator.credentials
                    .get({ signal: controller.signal, otp: { transport: ["sms"] } })
                    .then((otp) => {
                        if (otp?.type === "otp") {
                            otpInputRef?.current?.blur();
                            formik.setFieldValue(loginFormFieldNames.otp, otp?.code?.trim());
                            formik.handleSubmit();
                        }
                    })
                    .catch((e) => {
                        console.error("Please try manually with your number.", e);
                    });
            } catch (e) {
                console.error("Please try manually with your number.", e);
                return;
            }
        }
    };

    const successCallback = () => {
        setIsResendingOtp(false);
        timer(bufferTime, (t) => {
            setTimeRemaining(t);
        });
    };

    const { sendGAandMoEngageEvent } = useEventTrackerService();
    const handleEventTracking = (name: string, otherData?: GenericObject) => {
        const data = {
            source_screen: "phone_number_registration",
            phone_number: formik.values.phoneNumber,
            ...otherData
        };

        sendGAandMoEngageEvent({
            name,
            data
        });
    };

    const handleSmsResend = () => {
        handleEventTracking(GA4EventNames.LOGIN_OR_SIGNUP_MODAL["otp_via_message"]);

        setIsResendingOtp(true);
        autoReadOTP();
        onClickResendOtp?.({
            onSuccessCallback: successCallback
        });
    };

    const handleWAResend = () => {
        handleEventTracking(GA4EventNames.LOGIN_OR_SIGNUP_MODAL["otp_via_whatsapp"]);

        setIsResendingOtp(true);
        onClickResendOtp?.({
            isWhatsApp: true,
            onSuccessCallback: successCallback
        });
    };

    useEffect(() => {
        autoReadOTP();
        const timerId = timer(bufferTime, (t) => {
            setTimeRemaining(t);
        });
        return () => clearInterval(timerId);
    }, []);

    const { minutes, seconds } = getTimeRemaining(timeRemaining);

    const disableResendAction = timeRemaining > 0 || isResendingOtp;

    const isMobileScreen = useMediaQuery({ queryType: "mobile" });

    return (
        <Flex flexDirection="column" className={Styles.main} gap={isMobileScreen ? 20 : 15}>
            <Typography color={COLORS.GREY_500} weight="medium" align="center">
                We’ve sent an SMS with an OTP to your
            </Typography>
            <Flex gap={10} alignItems="center" justifyContent="center">
                <Typography color={COLORS.GREY_500} weight="medium" align="center">
                    phone{" "}
                    <Typography color={COLORS.BLACK} weight="medium">
                        +91 {formik.values.phoneNumber}
                    </Typography>
                </Typography>
                <TextCTA
                    size="m"
                    weight="regular"
                    underline
                    onClick={onClickEditNumber}
                    iconNode={<EditIconBlue />}
                    id={IDs.auth.editPhoneNumber}
                >
                    Edit
                </TextCTA>
            </Flex>
            <OTPInput
                id={IDs.auth.otp}
                ref={otpInputRef}
                length={otpLength}
                onChange={onChangeOTP}
                value={String(formik.values.otp ?? "")}
                errorMsg={String(formik.errors.otp ?? "")}
            />

            <Flex alignItems="center" flexDirection="column" justifyContent="center" gap={15}>
                {disableResendAction ? (
                    <Flex gap={5}>
                        <Typography size="sm" color={COLORS.GREY_500}>
                            Resend OTP in
                        </Typography>
                        <Typography size="sm" weight="semibold">
                            {" "}
                            {minutes}:{seconds}s
                        </Typography>
                    </Flex>
                ) : (
                    <Typography size="sm" color={COLORS.GREY_500}>
                        Resend OTP to
                    </Typography>
                )}
                {!disableResendAction ? (
                    <Flex alignItems="center" gap={15}>
                        <TextCTA
                            size="sm"
                            weight="regular"
                            underline
                            onClick={handleSmsResend}
                            id={IDs.auth.resendBtnSms}
                            iconNode={<SmsIcon />}
                        >
                            OTP via SMS
                        </TextCTA>
                        <TextCTA
                            size="sm"
                            weight="regular"
                            underline
                            onClick={handleWAResend}
                            id={IDs.auth.resendBtnWa}
                            iconNode={<WhatsappIcon />}
                        >
                            OTP via WhatsApp
                        </TextCTA>
                    </Flex>
                ) : (
                    <></>
                )}
            </Flex>
        </Flex>
    );
};

export default OtpField;
