import React, { ReactNode, useContext, useEffect } from "react";
import { useRouter } from "next/router";

import { AppContext } from "@vahak/core/dist/app-context";
import { toast } from "@vahak/core/dist/components/toast/toast";

interface PrivateRouteProps {
    children: ReactNode;
}
const PrivateRoute = ({ children }: PrivateRouteProps) => {
    const { isPhoneVerified, companyId } = useContext(AppContext);
    const router = useRouter();

    useEffect(() => {
        if (!isPhoneVerified || !companyId) {
            router.push("/login");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPhoneVerified, companyId]);
    return <>{isPhoneVerified && companyId && children}</>;
};

export default PrivateRoute;
