export const AB_TEST_CAMPAIGN_IDS: any = {
    downloadApp: "15",
    downloadAppProd: "29",
    newOnboardingFormDev: "23",
    newOnboardingFormProd: "26",
    mandateLoadLorryPostingDev: "28",
    mandatePostingShipperProd: "30",
    mandatePostingTruckerProd: "31",
    mandatePostingTransporterProd: "32"
};
