const IDs = {
    businessCards: {
        businessCardHomeScreenLink: "home_services_drpd_businesscard",
        businessCard: "businesscard",
        businessCardImage: "businesscard_image",
        businessCardShareButton: "businesscard_btn_share",
        businessCardDownloadButton: "businesscard_btn_download"
    },
    marketplace: {
        marketLoad: "mktHeader_btn_load",
        marketLorry: "mktHeader_btn_lorry"
    },
    profile: {
        tapNgoToggle: "tap-n-go-toggle"
    },
    mandatePopup: {
        mandateSignupBtn: "mandate-signup-btn"
    }
};

export default IDs;
