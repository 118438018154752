import API from "@vahak/core/dist/_services/const";
import { useVahakMutationQuery } from "@vahak/core/dist/hooks/useVahakQueryClient";
import useVahakHttpClient from "@vahak/core/dist/hooks/useVahakHttpClient";
import { ApiErrorResponseType } from "@vahak/core/dist/custom-types/api-error-response-type";
import queryNames from "../queryNames";
import { removeEmptyOrNullObjectValue } from "@vahak/core/dist/methods/remove-empty-or-null-object-value";

interface RaiseVasTicketPayload {
    material_name?: string;
    company_id: number;
    vas_type: number;
    no_of_devices?: number;
    plan_id?: number;
    reference_type: number;
    user_name: string;
    phone_number: string;
}

export default function useCreateVasTicket() {
    const { POST } = useVahakHttpClient();

    const createVasTicket = (data: RaiseVasTicketPayload) => {
        return POST<RaiseVasTicketPayload, unknown>(API.CREATE_VAS_TICKET, removeEmptyOrNullObjectValue(data));
    };

    return useVahakMutationQuery<unknown, ApiErrorResponseType, RaiseVasTicketPayload>(
        queryNames.createVasTicket,
        createVasTicket
    );
}
