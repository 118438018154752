import * as Yup from "yup";
import { companyNameRegExpMsg, phoneRegExp, referralCodeRegExp } from "@vahak/core/dist/constants/regex";
import { isValidCompanyName } from "@vahak/core/dist/methods/isValidCompanyName";
import { LOGIN_FORM_STEPS, loginFormFieldNames } from "../common/contant";

const { phoneNumber, otp, companyName, referralCode, currentPlaceID } = loginFormFieldNames;

export const getLoginValidationSchema = (key: LOGIN_FORM_STEPS) => {
    switch (key) {
        case LOGIN_FORM_STEPS.PHONE_NUMBER:
            return Yup.object().shape({
                [phoneNumber]: Yup.string()
                    .matches(phoneRegExp, "Invalid number")
                    .min(10, "Invalid number")
                    .max(10, "Invalid number")
                    .required("Required!")
            });
        case LOGIN_FORM_STEPS.OTP:
            return Yup.object().shape({
                [otp]: Yup.number()
                    .required("Required!")
                    .integer()
                    .positive()
                    .test("len", "", (val) => val?.toString().length === 4)
            });
        case LOGIN_FORM_STEPS.COMPANY_NAME:
            return Yup.object().shape({
                [companyName]: Yup.string().test("", companyNameRegExpMsg, isValidCompanyName),
                [currentPlaceID]: Yup.string().required("Required!"),
                [referralCode]: Yup.string().matches(referralCodeRegExp, "Please enter valid Referral code to proceed")
            });

        default:
            return Yup.object().shape({});
    }
    // return {
    //     [LOGIN_FORM_STEPS.PHONE_NUMBER]: Yup.object().shape({
    //         [phoneNumber]: Yup.string()
    //             .matches(phoneRegExp, "Invalid number")
    //             .min(10, "Invalid number")
    //             .max(10, "Invalid number")
    //             .required("Required!")
    //     }),
    //     [LOGIN_FORM_STEPS.OTP]: Yup.object().shape({
    //         [otp]: Yup.number()
    //             .required("Required!")
    //             .integer()
    //             .positive()
    //             .test("len", "", (val) => val?.toString().length === 4)
    //     }),
    //     [LOGIN_FORM_STEPS.COMPANY_NAME]: Yup.object().shape({
    //         [companyName]: Yup.string().test("", companyNameRegExpMsg, isValidCompanyName),
    //         [referralCode]: Yup.string().matches(referralCodeRegExp, "Please enter valid Referral code to proceed")
    //     })
    //     // [serviceType.id]: Yup.object().shape({
    //     //     [serviceType.fields.serviceType]: Yup.string().required("Required!")
    //     // })
    // };
};
