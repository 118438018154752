import { COOKIES } from "@vahak/core/dist/constants/misc";
import { getCookieByName } from "../../methods/getCookieByName";

const isReqFromFlutter = getCookieByName(COOKIES.fromFlutterApp) === "true" ? true : false;
export function GotBackToPreviousFlutterPage(message) {
    if (isReqFromFlutter) BackClickWebViewEvent.postMessage(message);
}

export function ClearAppCookiesAndLogoutFlutterPage(message) {
    if (isReqFromFlutter) LogoutWebViewEvent.postMessage(message);
}
