import { companyNameCharLimit } from "@vahak/core/dist/constants/regex";

export const formatCompanyName = (str: string) => {
    let result = str;
    result = result.replace(/[0-9][0-9][0-9]/, ""); // replace 2 or more digit
    result = result.replace(/[^a-z0-9 ]/gi, ""); // replace special character or anything else then chars,numbers and space
    result = result.replace(/\s+/g, " "); // allows only one space
    result = result.replace(/(\w)\1{2,}/g, "$1"); // if 3 same letters typed continuously then replace it with 1 letter
    result = result.slice(0, companyNameCharLimit);
    return result;
};
