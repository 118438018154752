import { IMG_BASE_URL, IMG_BASE_URL_NEXTJS } from "@vahak/core/dist/constants/misc";
import NextImage, { ImageLoader, ImageProps } from "next/image";

const myLoader: ImageLoader = ({ src, width, quality }) => {
    // return `${IMG_BASE_URL}/${width}/${src}`;
    // const isWebPNOTSupported = window.localStorage.getItem("isWebPSupported") === "0";
    // conso le.log("WEBP>>>>>>.", process.env.isWebPSupported);
    const isWebPNOTSupported = process.env.isWebPSupported === "0";
    return `${IMG_BASE_URL_NEXTJS}/${width}/${quality || 75}/${isWebPNOTSupported ? "default" : "webp"}/${src}`;
    // return `${IMG_BASE_URL}/${width}/${quality || 75}/webp/${src}`;
};

export interface AppImgProps extends ImageProps {}

const AppImg = ({ placeholder = "blur", ...props }: ImageProps) => {
    return (
        <NextImage
            loader={myLoader}
            placeholder={placeholder}
            blurDataURL={
                placeholder === "blur"
                    ? myLoader({
                          src: props.src as string,
                          width: 128,
                          quality: 1
                      })
                    : undefined
            }
            {...props}
            {...(props.unoptimized && {
                src: IMG_BASE_URL + props.src
            })}
        />
    );
};

export default AppImg;
