import router from "next/router";

export function getPathName() {
    let pathName = "";
    router.pathname.includes("web")
        ? (pathName = router.pathname.replace("/web", ""))
        : router.pathname.includes("mob")
        ? (pathName = router.pathname.replace("/mob", ""))
        : router.pathname;

    return pathName;
}
