import React, { useContext, useEffect, useState } from "react";
import { useRouter } from "next/router";
import classNames from "classnames";

import { ROUTES } from "@vahak/core/dist/constants/routeStrings";

// Styles
import Styles from "./DashboardNav.module.scss";

// Context
import { AppContext } from "@vahak/core/dist/app-context";

import { MarketType } from "@vahak/core/dist/custom-types/market-type";
import { NavBarMenuItems } from "../navbar-menu-items";
import Typography from "@vahak/core-ui/dist/components/Typography";
import COLORS from "@vahak/core-ui/dist/constants/colors";
import useMediaQuery from "@vahak/core-ui/dist/hooks/useMediaQuery";
import { useEventTrackerService } from "@vahak/core/dist/_services/hooks/useEventTrackerService";
import { useCsDashboard } from "@vahak/core/dist/hooks/useCsDashboard";

interface DashboardNavProps {
    fromUrl?: string;
    mktType?: string;
    showPostingIcon?: boolean;
    navbarName?: string;
}

export type NavItemsType = {
    id: string;
    label?: string;
    icon?: any;
    iconActive?: any;
    pathName: string;
    alternativePath?: string;
    clientAppEventName?: string;
}[];

const DashboardNav = ({
    fromUrl = ROUTES.marketplace.getUrl("load"),
    mktType,
    showPostingIcon = true,
    navbarName
}: DashboardNavProps) => {
    const { companyLogo, name, isPhoneVerified, companyId, userId, serviceType, setHistoryLocation } =
        useContext(AppContext);

    const mobileScreen = useMediaQuery({ queryType: "mobile" });

    const [isActive, setIsActive] = useState("");

    const router = useRouter();

    const { isCsDashboardUser } = useCsDashboard();

    //Events
    const { sendGAandMoEngageEvent } = useEventTrackerService();

    const handleMenu = (pathName: string, clientAppEventName?: string) => {
        setHistoryLocation?.(window?.location?.pathname);
        setIsActive(pathName);

        /**
         * Events
         */

        !isCsDashboardUser &&
            sendGAandMoEngageEvent({
                name: clientAppEventName as string,
                data: {
                    from_screen: router.asPath,
                    from: "bottom_nav"
                }
            });

        /**
         * Temp fix for sidenav multi click
         */

        let ROUTE_PATHNAME = router.pathname;

        //Jugaad
        if (router.pathname.includes("marketplace")) {
            ROUTE_PATHNAME = `/marketplace/${router?.query?.params?.[0] as MarketType}`;
        }

        if (ROUTE_PATHNAME.replace("/web", "").replace("/mob", "") !== pathName) {
            router.push(pathName);
        }
    };

    useEffect(() => {
        setIsActive(router.asPath);
    }, [router.asPath]);

    const NavItems: NavItemsType = NavBarMenuItems(navbarName, mktType);

    /**
     * Temporary fix
     * This whole file will be removed
     */
    const activeMenu = (pathName: string, id: string) => {
        let isActiveMenu;
        isActiveMenu = isActive.includes(pathName);

        return isActiveMenu;
    };

    return mobileScreen ? (
        <div className={Styles.bottomNavbarWrapper}>
            {NavItems.map(({ id, icon, iconActive, pathName, alternativePath, label, clientAppEventName }) => (
                <div
                    className={classNames(Styles.menuItem, Styles[id], activeMenu(pathName, id) ? Styles.active : "")}
                    key={id}
                    onClick={() => handleMenu(pathName, clientAppEventName)}
                    id={id}
                >
                    <span className={classNames(Styles.icon, Styles[id])}>
                        <i className={activeMenu(pathName, id) ? Styles.activeIcon : ""}>
                            {/* Need to update icon */}
                            {activeMenu(pathName, id) ? iconActive : icon}
                        </i>
                        <Typography mSize="s" align="center" as="span" color={COLORS.GREY_400}>
                            {label}
                        </Typography>
                    </span>
                </div>
            ))}
        </div>
    ) : (
        <></>
    );
};

export default DashboardNav;
