import classnames from "classnames";
import Link from "next/link";
import { useEffect, useState } from "react";

// Constants
import A from "@vahak/core/dist/components/app-anchor/A";
import { mostSearchedLinksMenusKeys } from "@vahak/core/dist/constants/mostSearchedLinks";
import { mostSearchedCategoryList } from "./mostSearchedCategoryList";

// Styles
import categoryListStyles from "./categoryListStyles.module.scss";

// Interface
interface Props {
    selectedList: string;
    isOpen?: boolean;
    isMobile?: boolean;
    limit?: number;
    title?: string;
}

const CategoryList = ({ selectedList, isOpen, isMobile, limit, title }: Props) => {
    const [showMoreCount, setShowMoreCount] = useState(limit ? limit : 5);
    useEffect(() => {
        if (isOpen) {
            setShowMoreCount(limit as number);
        }
    }, [isOpen]);

    const urlEndpointBasedOnSelectedList = () => {
        switch (selectedList) {
            case mostSearchedLinksMenusKeys.ONLINE_TRUCK_BOOKING:
            case mostSearchedLinksMenusKeys.LORRY:
                return "marketplace/lorry";
            case mostSearchedLinksMenusKeys.ONLINE_LOAD_BOOKING:
                return "marketplace/load";
            case mostSearchedLinksMenusKeys.TRANSPORT_SERVICE:
            case mostSearchedLinksMenusKeys.TRANSPORT_DIRECTORY:
            case mostSearchedLinksMenusKeys.TRANSPORTER:
                return "transporters";
            default:
                return "";
        }
    };

    const renderCityLink = (cityName: any) => {
        const city =
            cityName.toLowerCase().replace(" ", "-") !== "india" ? `/${cityName.toLowerCase().replace(" ", "-")}` : "";

        // if (CITIES_WITH_CITY_PAGES.includes(cityName.toLowerCase().replace(" ", "-"))) {
        //     return <AppLink href={`/city${city}`}>{cityName}</AppLink>;
        // } else {
        return <A href={`/${urlEndpointBasedOnSelectedList()}${city}`}>{cityName}</A>;
        // }
    };

    return (
        <>
            {isOpen && <h4 className={classnames(categoryListStyles.categoryHeading)}>{title} IN</h4>}
            <ul
                className={classnames(
                    categoryListStyles.mostSearchedCategoryList,
                    isOpen ? categoryListStyles.active : categoryListStyles.inactive,
                    isMobile ? categoryListStyles.mobile : categoryListStyles.web
                )}
            >
                {mostSearchedCategoryList[selectedList] &&
                    mostSearchedCategoryList[selectedList].slice(0, showMoreCount).map((cityName: any, i: number) =>
                        typeof cityName !== "object" ? (
                            <li className={classnames(categoryListStyles[selectedList])} key={i}>
                                {renderCityLink(cityName)}
                            </li>
                        ) : (
                            <li className={classnames(categoryListStyles[selectedList])} key={i}>
                                <A
                                    href={`/${urlEndpointBasedOnSelectedList()}/${cityName.src
                                        .toLowerCase()
                                        .replace(" ", "-")}-to-${cityName.dest.toLowerCase().replace(" ", "-")}`}
                                >
                                    {cityName.src} to {cityName.dest}
                                </A>
                            </li>
                        )
                    )}
            </ul>
            {selectedList !== "" && isOpen && isMobile && showMoreCount === limit ? (
                <div className={classnames(categoryListStyles.show_more)}>
                    <h4>
                        <div
                            onClick={() => {
                                setShowMoreCount(mostSearchedCategoryList[selectedList].length);
                            }}
                            className={classnames(categoryListStyles.link)}
                        >
                            <Link href="#" passHref prefetch={false}>
                                <u>Show more</u>
                            </Link>
                        </div>
                    </h4>
                </div>
            ) : isOpen && showMoreCount && showMoreCount > (limit as number) ? (
                <div className={classnames(categoryListStyles.show_more)}>
                    <h4>
                        <div
                            onClick={() => setShowMoreCount(limit as number)}
                            className={classnames(categoryListStyles.link)}
                        >
                            <Link href="#" passHref prefetch={false}>
                                <u>Show less</u>
                            </Link>
                        </div>
                    </h4>
                </div>
            ) : (
                ""
            )}
        </>
    );
};

export default CategoryList;
