import { generateFormFieldNames } from "../../../utils";

export enum LOGIN_FORM_STEPS {
    PHONE_NUMBER = "phoneNumber",
    OTP = "otp",
    COMPANY_NAME = "companyName",
    SUCCESS_SCREEN = "successScreen"
}

interface LoginFormMetaFields {
    showCurrentLocationField: boolean;
}

export interface LoginFormFieldsTypes extends LoginFormMetaFields {
    phoneNumber: string;
    otp: number | null;
    companyName: string;
    serviceType: string;
    referralCode: string;
    currentPlaceID: string;
    whatsappUpdatesEnabled: boolean;
}

export const initialLoginFormValues: LoginFormFieldsTypes = {
    phoneNumber: "",
    otp: null,
    companyName: "",
    serviceType: "",
    referralCode: "",
    whatsappUpdatesEnabled: true,
    currentPlaceID: "",
    showCurrentLocationField: true
};

export const loginFormFieldNames = generateFormFieldNames(initialLoginFormValues);

// export const LoginFormModel = {
//     phoneNum: phoneNumModel,
//     otp: otpModel,
//     companyName: companyNameModel,
//     serviceType: serviceTypeModel,
//     success: {
//         id: "success",
//         fields: {}
//     }
// };

// const LoginFormValidationModel = {
//     [LOGIN_FORM_STEPS.PHONE_NUMBER]: {
//         id: LOGIN_FORM_STEPS.PHONE_NUMBER,

//     }
// };
