import classNames from "classnames";
import React, { ReactNode, useContext, useState } from "react";

// Style
import Styles from "./ServiceTypeSelection.module.scss";

// Assets

//Context
import { AppContext } from "@vahak/core/dist/app-context";

// Constants
import { CompanyServiceType } from "@vahak/core/dist/_services/auth.service";
import Typography from "@vahak/core-ui/dist/components/Typography/Typography";
import useMediaQuery from "../../../hooks/useMediaQuery";
import { MembershipPlans } from "../../../constants/vas";

export interface ServiceTypeOptionsType {
    label: string;
    shortLabel: string;
    value: string;
    description: string;
    icon: string;
    lookingForLabel: string;
    lookingForAlternative: string;
    lookingForIcon: string;
    membershipCategoryIconName: string;
    membershipCategoryActiveIconName: string;
    membershipPlanOrderKey: number;
    membershipServiceTypeId: MembershipPlans;
}

export const ServiceTypeOptions: ServiceTypeOptionsType[] = [
    {
        label: "Truck Owner/Driver",
        shortLabel: "Trucker",
        value: CompanyServiceType.FLEET_OWNER,
        description: "I have truck(s) and I am looking for loads",
        icon: "/lorry_owner_icon.svg",
        lookingForLabel: "Find Loads",
        lookingForAlternative: "Find Shippers",
        lookingForIcon: "find-load.svg",
        membershipCategoryIconName: "/Membership_trucker.svg",
        membershipCategoryActiveIconName: "/Membership_trucker_white.svg",
        membershipPlanOrderKey: 2,
        membershipServiceTypeId: MembershipPlans.TRUCKER
    },
    {
        label: "Shipper",
        shortLabel: "Shipper",
        value: CompanyServiceType.SHIPPER,
        description: "I have loads and I am searching for trucks",
        icon: "/load_owner_icon.svg",
        lookingForLabel: "Find Lorries",
        lookingForAlternative: "Find Truck owners",
        lookingForIcon: "find-lorry.svg",
        membershipCategoryIconName: "/Membership_shipper.svg",
        membershipCategoryActiveIconName: "/Membership_shipper_white.svg",
        membershipPlanOrderKey: 1,
        membershipServiceTypeId: MembershipPlans.SHIPPER
    },
    {
        label: "Transporter",
        shortLabel: "Transporter",
        value: CompanyServiceType.TRANSPORT_CONTRACTOR,
        description: "I am searching for both loads and lorries",
        icon: "/transporter_icon.svg",
        lookingForLabel: "Find Loads & Lorries",
        lookingForAlternative: "Find Both",
        lookingForIcon: "find-load-lorry.svg",
        membershipCategoryIconName: "/Membership_transporter.svg",
        membershipCategoryActiveIconName: "/Membership_transporter_white.svg",
        membershipPlanOrderKey: 3,
        membershipServiceTypeId: MembershipPlans.TRANSPORTER
    }
];

interface ServiceTypeSelectionProps {
    className?: string;
    handleChange: (val: string) => void;
    name?: string;
    serviceTypeModal?: boolean;
}

const ServiceTypeSelection = ({ handleChange, name, className, serviceTypeModal }: ServiceTypeSelectionProps) => {
    const { setServiceType } = useContext(AppContext);
    const [selectedServiceTypes, setSelectedServiceTypes] = useState();
    const handleServiceTypeSelection = (value: any) => {
        setSelectedServiceTypes(value);
        handleChange(value);
        setServiceType?.(value);
    };

    const isMobileScreen = useMediaQuery({ queryType: "mobile" });

    return (
        <>
            <div className={classNames(Styles.serviceTypeSelectionWrapper, className)}>
                <div className={Styles.sectionTitle}>
                    <Typography size="m" weight="bold">
                        What do you do?
                    </Typography>
                </div>
                {ServiceTypeOptions.map(({ label, value, description, icon }, index) => (
                    <div
                        className={classNames(
                            Styles.serviceTypeInputContainer,
                            selectedServiceTypes === value && Styles.isActive,
                            isMobileScreen && serviceTypeModal && Styles.serviceTypeModal
                        )}
                        key={index}
                        onClick={() => handleServiceTypeSelection(value)}
                    >
                        <div className={classNames(Styles.serviceTypeInputWrapper)}>
                            {isMobileScreen && !serviceTypeModal ? (
                                <div className={classNames(Styles.iconWrapper)}>{icon}</div>
                            ) : (
                                <div className={classNames(Styles.radioBtn)}>
                                    <span
                                        className={classNames(
                                            Styles.circle,
                                            selectedServiceTypes === value && Styles.isActive
                                        )}
                                    ></span>
                                </div>
                            )}
                            <div className={classNames(Styles.serviceTypeSelectionContent)}>
                                <span className={classNames(Styles.label)}>{label}</span>
                                <span className={classNames(Styles.description)}>{description}</span>
                            </div>
                        </div>
                        {!isMobileScreen && <div className={classNames(Styles.iconWrapper)}>{icon}</div>}
                    </div>
                ))}
            </div>
        </>
    );
};

export default ServiceTypeSelection;
