import React, { useEffect, useState } from "react";

interface customQueryProps {
    queryType: "max-width" | "min-width" | "max-height" | "min-height";
    sizePx: string;
}

interface defaultQueryProps {
    queryType: "mobile" | "medium-screen" | "desktop";
}

type Props = customQueryProps | defaultQueryProps;

const useMediaQuery = ({ queryType, ...props }: Props) => {
    const { sizePx } = props as customQueryProps;

    const [matches, setMatches] = useState(true);

    let query: string = "";

    switch (queryType) {
        case "max-width":
            query = `(max-width: ${sizePx}px)`;
            break;
        case "min-width":
            query = `(min-width: ${sizePx}px)`;
            break;
        case "max-height":
            query = `(max-height: ${sizePx}px)`;
            break;
        case "min-height":
            query = `(min-height: ${sizePx}px)`;
            break;
        case "mobile":
            query = `(max-width: 576px)`;
            break;
        case "medium-screen":
            query = `(max-width: 991px)`;
            break;
        case "desktop":
            query = `(min-width: 992px)`;
            break;

        default:
            break;
    }

    useEffect(() => {
        const media = window.matchMedia(query);
        if (media.matches !== matches) {
            setMatches(media.matches);
        }
        const listener = () => setMatches(media.matches);
        window.addEventListener("resize", listener);
        return () => window.removeEventListener("resize", listener);
    }, [matches, query]);

    return matches;
};

export default useMediaQuery;
