import React, { ReactNode, useContext, useEffect } from "react";
import { useRouter } from "next/router";

// Styles
import Styles from "./DefaultLayoutWrapper.module.scss";

// Layout
import DefaultLayout, { DefaultLayoutProp } from "../default-layout/DefaultLayout";

// Context
import { AppContext } from "@vahak/core/dist/app-context";

//Constants
import { ROUTES } from "@vahak/core/dist/constants/routeStrings";

interface DefaultLayoutWrapperProps extends DefaultLayoutProp {
    children: ReactNode;
    pageRoute?: string;
}

const DefaultLayoutWrapper = ({ ...props }: DefaultLayoutWrapperProps) => {
    const { isPhoneVerified, companyId, shouldPreventLayoutSwitch } = useContext(AppContext);
    const { content: Content, children, navContent, pageRoute } = props;

    const router = useRouter();

    useEffect(() => {
        /**
         * Temp fix
         * home page fix
         */
        if (!shouldPreventLayoutSwitch)
            setTimeout(() => {
                if (["/", "/web", "/mob"].includes(router.pathname) && isPhoneVerified && !!companyId) {
                    router.push(
                        {
                            pathname: ROUTES.dashboard.getUrl()
                        },
                        undefined,
                        { shallow: true }
                    );
                }
            }, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.pathname, isPhoneVerified, companyId, shouldPreventLayoutSwitch]);

    return <DefaultLayout navContent={navContent}>{children}</DefaultLayout>;
};

export default DefaultLayoutWrapper;
