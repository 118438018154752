import { CommonProps } from "@vahak/core-ui/dist/types";
import { UserLoadInfoTypes, UserLorryInfoTypes } from "@vahak/core/dist/custom-types";
import { createContext, useState, useContext } from "react";
import { LoadAllDetails } from "./services/load/useGetLoadAllDetailsV2/type";
import { PaymentInfo } from "./services/bid/useGetBidPaymentInfo";
import { LorryCardV2 } from "./services/lorry/useGetAllLorryDetailsV2/type";

export interface IDetailsPageContextProvider extends CommonProps {}

export const DetailsPageContextProvider = ({ children }: IDetailsPageContextProvider) => {
    const [detailsPageData, setDetailsPageData] = useState<IDetailsPageContextData>({});

    const updateDetailsPageData = (details: IDetailsPageContextData) => {
        setDetailsPageData((prevDetails) => ({ ...prevDetails, ...details }));
    };

    const clearDetailsPageContext = () => {
        setDetailsPageData({});
    };

    return (
        <DetailsPageContext.Provider value={{ detailsPageData, updateDetailsPageData, clearDetailsPageContext }}>
            {children}
        </DetailsPageContext.Provider>
    );
};

export interface docTypeContext {
    name: string;
    url: string;
    status: number;
    createdBy: number;
    groupId?: number;
    docType?: number;
    timeStamp: number | string;
}
export interface IDetailsPageContextData {
    type?: "load" | "lorry";
    title?: string;
    onClickBack?: () => void;
    onSuccess?: () => void;
    postSuccess?: () => void;
    onFailure?: () => void;
    postFailure?: () => void;
    onSettle?: () => void;
    queriesToInvalidate?: {
        success?: string[];
        error?: string[];
        back?: string[];
    };

    //Main props
    loadInfo?: UserLoadInfoTypes;
    lorryInfo?: UserLorryInfoTypes;
    loadDetailsV2?: LoadAllDetails;
    lorryDetailsV2?: LorryCardV2;
    PaymentInfo?: PaymentInfo[];
    goodsDoc?: docTypeContext;
    lrDoc?: docTypeContext;
    ewayDoc?: docTypeContext;
    supportingDoc?: docTypeContext[];

    //Lorry doc
    podDoc?: docTypeContext;
    rcDoc?: docTypeContext;
    lorryFitnessDoc?: docTypeContext;
    tdsDoc?: docTypeContext;
    vehicleInsuranceDoc?: docTypeContext;
    vehicleOwnerAadhaarDoc?: docTypeContext;
    driverLicenseDoc?: docTypeContext;
    driverAadhaarDoc?: docTypeContext;
}

export interface IDetailsPageContext {
    detailsPageData?: IDetailsPageContextData;
    updateDetailsPageData?: (data: IDetailsPageContextData) => void;
    clearDetailsPageContext?: () => void;
}

const DetailsPageContext = createContext<IDetailsPageContext>({});

export const useDetailsPageContext = () => useContext(DetailsPageContext);
