import React, { useContext, useEffect, useState } from "react";
import { FormikProps } from "formik";

//Style
import Styles from "./CompanyRegistrationField.module.scss";

//Constant
import { LoginFormFieldsTypes, loginFormFieldNames } from "../../common/contant";

//Asset
import CompanyRegistrationIcon from "@vahak/core/dist/graphic-images/register-company.svg";

//Components
import Typography from "@vahak/core-ui/dist/components/Typography";
import Input, { InputChangeHandler } from "@vahak/core-ui/dist/components/Input";
import { GA4EventNames, IDs, referralCodeCharLimit } from "@vahak/core/dist/constants";
import { removeNonAlphaNumeric } from "@vahak/core-ui/dist/methods";
import { formatCompanyName } from "../../../../methods/formatCompanyName";
import Flex from "@vahak/core-ui/dist/layout/Flex";
import Button from "@vahak/core-ui/dist/components/Button";
import TextCTA from "@vahak/core-ui/dist/components/TextCTA";
import COLORS from "@vahak/core-ui/dist/constants/colors";
import { LocationSearchSource } from "@vahak/core/dist/_services/city-search.service";
import { PlaceSearchResult } from "@vahak/core/dist/custom-types";
import UserCurrentLocation from "../../../posting-form/common/current-location/UserCurrentLocation";
import { AppContext } from "@vahak/core/dist/app-context";
import { useEventTrackerService } from "@vahak/core/dist/_services";
import { GenericObject } from "@vahak/core-ui/dist/types";
import { ServiceTypeOptions } from "../../../auth/service-type-selection/ServiceTypeSelection";
import useMediaQuery from "@vahak/core-ui/dist/hooks/useMediaQuery";

interface CompanyRegistrationFieldProps {
    formik: FormikProps<LoginFormFieldsTypes>;
}

const CompanyRegistrationField = ({ formik }: CompanyRegistrationFieldProps) => {
    const { globalData, referralCode } = useContext(AppContext);
    const [showReferralCode, setShowReferralCode] = useState(Boolean(referralCode));
    const userPlaceInfo = globalData?.["userPlaceInfo"];

    const [location, setLocation] = useState<PlaceSearchResult | undefined>(userPlaceInfo);

    const { sendGAandMoEngageEvent } = useEventTrackerService();

    const handleEventTracking = (name: string, otherData?: GenericObject) => {
        const data = {
            source_screen: "company_registration",
            services: ServiceTypeOptions.find((val) => val.value === formik?.values?.serviceType)?.label,
            company_name: formik?.values?.companyName,
            company_location: location?.city || userPlaceInfo?.city,
            ...otherData
        };

        sendGAandMoEngageEvent({
            name,
            data
        });
    };

    const handleAndUpdateReferralCodeInput = (str: string = "") => {
        const value = removeNonAlphaNumeric(str).toUpperCase().slice(0, referralCodeCharLimit);
        formik?.setFieldValue(loginFormFieldNames.referralCode, value);
    };

    const handleAndUpdateCompanyNameInput = (str: string = "") => {
        const value = formatCompanyName(str);
        formik?.setFieldValue(loginFormFieldNames.companyName, value);
    };

    const onChangeTextInput: InputChangeHandler = (e) => {
        const name = e.target.name;
        let value = e.target.value;

        if (name) {
            switch (name) {
                case loginFormFieldNames.referralCode: {
                    return handleAndUpdateReferralCodeInput(value);
                }
                case loginFormFieldNames.companyName: {
                    return handleAndUpdateCompanyNameInput(value);
                }
                default: {
                    formik?.setFieldValue(name, value);
                }
            }
        }
    };

    const getErrorMsg = (fieldName: string) => {
        // @ts-ignore
        return formik.touched[fieldName] ? formik.errors[fieldName] : "";
    };

    const isMobileScreen = useMediaQuery({ queryType: "mobile" });
    return (
        <div className={Styles.main}>
            <Flex flexDirection="column" justifyContent="center" alignItems="center" gap={isMobileScreen ? 20 : 14}>
                <CompanyRegistrationIcon />

                <Typography size="md" weight="semibold">
                    Enter your/company name
                </Typography>

                <div className={Styles.inputWrapper}>
                    <Input
                        label=""
                        name={loginFormFieldNames.companyName}
                        onChange={onChangeTextInput}
                        type="text"
                        placeholder="ABC Logistics"
                        value={formik.values.companyName}
                        id={IDs.auth.userName}
                        errorMsg={getErrorMsg(loginFormFieldNames.companyName)}
                        onBlur={(e) => {
                            // formik.handleBlur?.(e);
                            handleEventTracking(GA4EventNames.COMPANY_REGISTRATION["company_name_entered"]);
                        }}
                        length={50}
                        autoFocus
                    />
                </div>
                {formik.values.showCurrentLocationField ? (
                    <div className={Styles.inputWrapper}>
                        <UserCurrentLocation
                            id={IDs.auth.locationInput}
                            placeholder="Enter your/company location"
                            onChange={(e) => {
                                setLocation(e);
                                formik.setFieldValue(loginFormFieldNames.currentPlaceID, e?.place_id);

                                handleEventTracking(GA4EventNames.COMPANY_REGISTRATION["company_location_selected"], {
                                    company_location: e?.city
                                });
                            }}
                            value={location}
                            dropdownProps={{
                                maxMenuHeight: 80,
                                menuPosition: "absolute"
                            }}
                            locationSearchSource={LocationSearchSource.CompanyRegistration}
                        />
                    </div>
                ) : (
                    <></>
                )}

                {showReferralCode ? (
                    <div className={Styles.inputWrapper}>
                        <Input
                            label=""
                            name={loginFormFieldNames.referralCode}
                            onChange={onChangeTextInput}
                            type="text"
                            placeholder="VAHAK100"
                            value={formik.values.referralCode}
                            id={IDs.auth.referralInput}
                            errorMsg={getErrorMsg(loginFormFieldNames.referralCode)}
                            onBlur={(e) => {
                                formik.handleBlur?.(e);
                                handleEventTracking(GA4EventNames.COMPANY_REGISTRATION["referral_code_entered"]);
                            }}
                            length={referralCodeCharLimit}
                            autoFocus={!formik.values.referralCode}
                        />
                    </div>
                ) : (
                    <></>
                )}

                <Button
                    isRounded
                    blockBtn
                    disabled={!formik.isValid || !Boolean(formik.values.companyName)}
                    id={IDs.auth.submitBtn}
                >
                    Submit
                </Button>

                {!showReferralCode ? (
                    <Typography color={COLORS.GREY_700} size="sm">
                        Have a{" "}
                        <TextCTA
                            id={IDs.auth.referralToggleBtn}
                            underline
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setShowReferralCode(true);
                                handleEventTracking(GA4EventNames.COMPANY_REGISTRATION["have_a_referral_code_clicked"]);
                            }}
                        >
                            Referral code?
                        </TextCTA>
                    </Typography>
                ) : (
                    <></>
                )}
            </Flex>
        </div>
    );
};

export default CompanyRegistrationField;
