import { forwardRef, useContext } from "react";

import classNames from "classnames";

// Components
import Col from "@vahak/core/dist/components/grid/col/Col";
import Row from "@vahak/core/dist/components/grid/row/Row";
// Layout
import { CommonLayoutProps } from "../layout-types";

// Styles
import Styles from "./SideNavLayout.module.scss";

//Icons
import VahakLogoLightBlue from "@vahak/core/dist/icons/VahakLogoLightBlue.svg";
import AppLink from "../../../components/utils/app-link/AppLink";
import { IDs } from "@vahak/core/dist/constants/automation";

import { ROUTES } from "@vahak/core/dist/constants/routeStrings";

import { AppContext } from "@vahak/core/dist/app-context";

export interface SideNavLayoutProps extends CommonLayoutProps {
    isCollapsed?: boolean;
    sideBarWrapperClass?: string;
    contentWrapperClass?: string;
}

const SideNavLayout = forwardRef<HTMLDivElement, SideNavLayoutProps>(
    (
        {
            navContent,
            content: Content,
            children,
            className,
            sideBarWrapperClass,
            contentWrapperClass,
            isCollapsed = false
        },
        ref
    ) => {
        const { companyId } = useContext(AppContext);
        return (
            <Row
                className={classNames(
                    Styles.main,
                    isCollapsed && Styles.collapsedSidebar,
                    !isCollapsed && "isExpanded",
                    className
                )}
            >
                <Col className={classNames(Styles.sideBarWrapper, sideBarWrapperClass, "sideNavLayout_sideBarWrapper")}>
                    <div className={Styles.sideBarContainerWrap} ref={ref}>
                        <div className={Styles.sideBarContainer}>
                            <AppLink
                                id={IDs.common.vahakLogo}
                                href={companyId ? ROUTES.dashboard.getUrl() : "/"}
                                // title="Vahak - Book Trucks, Trailers, Containers from Thousands of Trusted Transport businesses and Lorry Owners from All India"
                                passHref
                            >
                                <div className={Styles.logoContainer}>
                                    <VahakLogoLightBlue />
                                </div>
                            </AppLink>
                            {navContent}
                        </div>
                    </div>
                </Col>

                <Col
                    xl={isCollapsed ? 12 : 10}
                    lg={isCollapsed ? 12 : 12}
                    md={isCollapsed ? 12 : 12}
                    xs={12}
                    className={classNames(Styles.contentWrapper, contentWrapperClass)}
                >
                    {Content ? <Content /> : children}
                </Col>
            </Row>
        );
    }
);

export default SideNavLayout;
