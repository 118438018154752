import React, { useContext } from "react";

// Context
import { AppContext } from "@vahak/core/dist/app-context";

// Layouts
import SideNavLayoutWrapper from "../SideNavLayoutWrapper/SideNavLayoutWrapper";
import BottomNavLayout, { BottomNavLayoutProps } from "../bottom-nav-layout/BottomNavLayout";
import SideNavLayout, { SideNavLayoutProps } from "../side-nav-layout/SideNavLayout";
import TopNavLayout, { TopNavLayoutProps } from "../top-nav-layout/TopNavLayout";
import useMediaQuery from "@vahak/core-ui/dist/hooks/useMediaQuery";
import TopNavNew from "../top-nav-new/TopNavNew";

export interface DefaultLayoutProp extends TopNavLayoutProps, SideNavLayoutProps, BottomNavLayoutProps {}

const DefaultLayout = ({ ...props }: DefaultLayoutProp) => {
    const { isPhoneVerified, shouldPreventLayoutSwitch, companyId } = useContext(AppContext);
    const { content: Content, children, navContent } = props;

    //TODO: GOWDHAM - Need to refactor this issue
    const mobileScreen = useMediaQuery({ queryType: "mobile" });

    if (shouldPreventLayoutSwitch || (!isPhoneVerified && !companyId) || (isPhoneVerified && !companyId)) {
        return (
            <TopNavLayout navContent={<TopNavNew />} content={Content}>
                {children}
            </TopNavLayout>
        );
    }

    if (isPhoneVerified && companyId && !mobileScreen) {
        return <SideNavLayoutWrapper>{Content ? <Content /> : children}</SideNavLayoutWrapper>;
    }

    if (isPhoneVerified && companyId && mobileScreen) {
        return (
            // <BottomNavLayout topNavContent={<Topbar t={t} router={router} />} navContent={navContent}>
            <BottomNavLayout topNavContent={<TopNavNew />} navContent={navContent}>
                {Content ? <Content /> : children}
            </BottomNavLayout>
        );
    }

    return <></>;
};

export default DefaultLayout;
