import Link, { LinkProps } from "next/link";
import React, { CSSProperties, HTMLAttributeAnchorTarget } from "react";
import classNames from "classnames";

import styles from "./AppLink.module.scss";

export interface AppLinkProps extends LinkProps {
    onClick?: React.MouseEventHandler;
    className?: string;
    style?: CSSProperties;
    itemProp?: string;
    title?: string;
    id?: string;
    target?: HTMLAttributeAnchorTarget;
}

const AppLink = ({
    className,
    style,
    children,
    title,
    id,
    target,
    ...props
}: React.PropsWithChildren<AppLinkProps>) => {
    return (
        <Link {...props} passHref locale={""} prefetch={false}>
            <a className={classNames(styles.a, className)} style={style} title={title} id={id} target={target}>
                {children}
            </a>
        </Link>
    );
};

export default AppLink;
