import { QueryNames } from "@vahak/core/dist/_services/hooks/queryNames";

import API from "@vahak/core/dist/_services/const";
import { UserAction, UserActionRequiredResponse } from "@vahak/core/dist/constants/userAction";
import { ApiErrorResponseType } from "@vahak/core/dist/custom-types/api-error-response-type";
import { ClientHookOptions, useVahakQuery } from "@vahak/core/dist/hooks/useVahakQueryClient";

import useVahakHttpClient from "@vahak/core/dist/hooks/useVahakHttpClient";

export interface UserActionValidationParams {
    companyId: number;
    action:
        | UserAction.POST_LOAD
        | UserAction.POST_LORRY
        | UserAction.LOAD_BID
        | UserAction.LORRY_BID
        | UserAction.SEND_C2C_REQUEST
        | UserAction.SEND_CONNECTION_REQUEST
        | UserAction.SIM_TRACKING_REQUEST;
}

export interface UserActionValidationResponse {
    data: {
        can_continue: boolean;
        action_required:
            | UserActionRequiredResponse.CLEAR_CASHBACK_COINS
            | UserActionRequiredResponse.VERIFY_AADHAAR
            | UserActionRequiredResponse.VERIFY_PAN
            | UserActionRequiredResponse.VERIFY_GST
            | UserActionRequiredResponse.BUY_MEMBERSHIP
            | UserActionRequiredResponse.TOP_UP_MEMBERSHIP
            | UserActionRequiredResponse.UPGRADE_MEMBERSHIP
            | UserActionRequiredResponse.DAILY_MEMBERSHIP_LIMIT_EXHAUSTED
            | UserActionRequiredResponse.EDIT_COMPANY_NAME
            | UserActionRequiredResponse.MEMBERSHIP_EXPIRED;
        cashback_balance: number;
        cashback_lower_threshold: number;
    };
}

export const useUserActionValidationService = (
    options: ClientHookOptions<UserActionValidationResponse, unknown, UserActionValidationResponse>,
    { companyId, action }: UserActionValidationParams
) => {
    const { GET } = useVahakHttpClient();
    const validateUserAction = () => {
        const queryObj = {
            c_i: companyId,
            action
        };
        return GET<UserActionValidationResponse>(API.GET_USER_ACTION_VALIDATION, queryObj);
    };
    /**
     * If you don't know the error type pass unknown
     */
    return useVahakQuery<UserActionValidationResponse, ApiErrorResponseType, UserActionValidationResponse>(
        [QueryNames.userActionValidation],
        validateUserAction,
        {
            ...options,
            select: (data: UserActionValidationResponse) => data
        }
    );
};
